/* eslint-disable no-underscore-dangle */
/* eslint-disable no-console */
import React from 'react';
import fetch from 'isomorphic-fetch';
import { withRouter } from 'next/router';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import ReactDOM from 'react-dom';
import * as turf from '@turf/turf';
import { withTranslation } from 'react-i18next';
import { MapToolsCtx } from '@/src/contexts';
import {
  changeURLPage, replaceURLPage, getMetersKm, addQueryStringToUrl, hostResolver
} from '@/src/helpers';
import UserMarker from '@/src/components/UserMarker';
import BuildingMarker from '@/src/components/BuildingMarker';
import UserMarkerIcon from '@/src/components/UserMarker/UserMarkerIcon';
import BuildingMarkerIcon from '@/src/components/BuildingMarker/BuildingMarkerIcon';
import MapPlaceMarker from '@/src/components/MapPlaceMarker';
import {
  setMapMode,
  getRoute,
  toggleMapShow,
  clearSearchResults,
  toggleShareModal,
  toggleLevelBtns,
  clearRoutesStore,
  actionGetStreedByOSMID,
  actionGetBuildingByOSMID,
  clearObject,
  initOnMount,
} from '@/src/redux/actions';
import { AUTHORIZATION, USER_MARKERS_URL, GET_NEAR_URL } from '@/constants';
import { StartIcon, EndIcon } from '@/src/components/RulerIcons';
import theme from '@/src/theme';
import withMap from './withMap';
import withMapRouting from './withMapRouting';

// const canUseDOM = typeof window !== 'undefined';

const GEOJSON = {
  type: 'FeatureCollection',
  features: [],
};

const BUS_STOPS = {
  type: 'FeatureCollection',
  features: [],
};

const LINESTRING = {
  type: 'Feature',
  geometry: {
    type: 'LineString',
    coordinates: [],
  },
};

const CLUSTER_COLLECTION = {
  type: 'FeatureCollection',
  features: null,
};

let SIMPLE_MARKER = null;

const DEFAULT_LAYERS = ['mapMultiLine', 'mapLine', 'publicRouteFrom', 'publicRouteTo', 'publicRouteTransfer'];

const withMapTools = (Component) => {
  let mapboxgl = null;
  if (typeof window !== 'undefined') {
    // eslint-disable-next-line global-require
    mapboxgl = require('mapbox-gl');
  }
  class WithMapTools extends React.Component {
    constructor(props) {
      super(props);
      this.state = {
        MapGL: props.MapGL
      };
      this.lastRoute = '';
      this.inited = false;
      this.userLocation = null;
      this.markerStart = null;
      this.markerEnd = null;
      this.placeMarker = null;
      this.markerModeCache = [];
      this.buildingMarker = null;
      this.mesurePoints = null;
    }

    static getDerivedStateFromProps(props, state) {
      return { MapGL: props.MapGL };
    }

    componentDidUpdate() {
      const {
        state: { MapGL },
        clustersClick,
        unclusteredClick,
        unclusteredMouseover,
        unclusteredMouseOut,
        handleMapViewportChange,
      } = this;
      const {
        router
      } = this.props;
      if (!MapGL.mock && !this.inited) {
        this.initUserLocation();
        const {
          query: { markersId, customPage },
        } = router;
        MapGL.on('click', this.mapClickHandler);
        MapGL.on('zoomend', this.mapZoomHandler);
        MapGL.on('moveend', this.mapZoomHandler);        
        MapGL.on('click', 'clusters', clustersClick());
        MapGL.on('click', 'unclustered-point', unclusteredClick());
        MapGL.on('mouseover', 'unclustered-point', unclusteredMouseover());
        MapGL.on('mouseout', 'unclustered-point', unclusteredMouseOut());
        MapGL.on('moveend', handleMapViewportChange());
        MapGL.on('zoomend', handleMapViewportChange());
        MapGL.on('mouseout', 'unclustered-point', unclusteredMouseOut());
        if (markersId) {
          this.getUserMarkers(markersId);
        }
        this.addMobilePitchGesture(MapGL);
        this.initReverseGeocoding();
        this.handleRouterPage(customPage);
        
        this.inited = true;
      }
    }

    toggleLevelButtons = (lvls) => {
      const { actionToggleLevelBtns } = this.props;
      actionToggleLevelBtns(lvls);
    }

    checkLeveledInViewport = () => {
      // const tmp = {};
      const { MapGL } = this.state;
      const levels = [-2, -1, 'P', 1, 2, 3, 4, 5, 6, 7, 8];
      let sortedLevels = [];
      const validLayers = [];
      levels.map((lvl) => {
        if (MapGL.getLayer(`vector-building-parts-${lvl}`)) {
          validLayers.push(`vector-building-parts-${lvl}`);
        }
      });
      let leveledLayers = null;
      if (MapGL.getLayer(`vector-building-top`)) {
        leveledLayers = MapGL.queryRenderedFeatures(null, { layers: ['vector-building-top'] }); 
      }
      if (!leveledLayers || !leveledLayers.length) return null;
      leveledLayers.map((layer) => {
        if (layer.properties && layer.properties.levels && layer.properties.levels.length) {
          const mapLevels = layer.properties.levels.split(',');
          const temp = levels.reduce((c, v, i) => Object.assign(c, { [v]: i }), {}); 
          sortedLevels = mapLevels.sort((a, b) => temp[a] - temp[b]);
          return sortedLevels;
        }
      });
      return sortedLevels;
    }

    mapZoomHandler = (e) => {
      const { showLevelBtns } = this.props;
      const map = e.target;
      const zoom = map.getZoom();
      if (zoom >= 16) {
        const hasLeveledFeatures = this.checkLeveledInViewport();
        if (hasLeveledFeatures && hasLeveledFeatures.length) {
          this.toggleLevelButtons(hasLeveledFeatures);
        } else if (showLevelBtns) {
          this.toggleLevelButtons(null);
        }
        // const layer = MapGL.getLayer('vector-building-parts-1');
        // if (!layer) return;
        // MapGL.setPaintProperty('vector-building-parts-1', 'fill-extrusion-opacity', 0.5);
        return;
      }
      if (showLevelBtns) {
        this.toggleLevelButtons(null);
      }
    }

    handleMapViewportChange = () => (e) => {
      const { MapGL } = this.state;
      const zoom = MapGL.getZoom();
      const pitch = MapGL.getPitch();
      const bearing = MapGL.getBearing();
      const center = MapGL.getCenter();
      const viewport = {
        zoom,
        pitch,
        bearing,
        center
      };
      if (typeof window !== 'undefined') {
        window.postMessage(JSON.stringify({ viewport }), '*');
      }
    };

    initReverseGeocoding = () => {
      const { router: { query } } = this.props;
      const { MapGL } = this.state;
      let popup = null;
      const { viewType } = query;
      const highlightedBuildings = viewType === 'sateliteStyle' ? '' : 'vector-housenumber-copy';
      const highlightedStreet = viewType === 'sateliteStyle' ? '' : 'vector-highway-name-secondary';
      const markerHolder = document.createElement('div');

      ReactDOM.render(<BuildingMarkerIcon />, markerHolder);
      popup = new mapboxgl.Popup({
        offset: { bottom: [0, -26] },
        closeOnClick: true,
        className: 'reverseBuildAddr'
      });
      // popup.setDOMContent(popupHolder);
      this.buildingMarker = new mapboxgl.Marker({
        radius: 7,
        element: markerHolder,
        fillOpacity: 0,
        draggable: false,
        offset: [0, 0],
        anchor: 'bottom',
        setZIndex: 9999999,
      });
      this.buildingMarker.setPopup(popup);
      this.buildingMarker.setLngLat([0, 0]);
      MapGL.addLayer({
        'id': 'highlightedBuildings',
        'minzoom': 14,
        'type': 'fill',
        'source': 'openmaptiles',
        'source-layer': 'building',
        'paint': {
          'fill-color': 'rgba(255,133,2,.1)',
        },
        'filter': [
          'all',
          [
            'in',
            'osm_id',
            'null'
          ]
        ],
      }, highlightedBuildings);
      MapGL.addLayer({
        'id': 'highlightedStreet',
        'minzoom': 14,
        'type': 'line',
        'source': 'openmaptiles',
        'source-layer': 'transportation',
        'paint': {
          'line-color': 'rgba(255,133,2,.1)',
          'line-width': {
            base: 1.2,
            stops: [
              [
                9,
                0
              ],
              [
                10,
                0
              ],
              [
                11,
                2.5
              ],
              [
                12,
                3
              ],
              [
                12.5,
                4
              ],
              [
                13,
                4
              ],
              [
                13.5,
                4
              ],
              [
                14,
                5
              ],
              [
                15,
                6
              ],
              [
                16,
                9
              ],
              [
                17,
                17
              ],
              [
                18,
                28
              ],
              [
                19,
                39
              ]
            ]
          },
        },
        'filter': [
          'all',
          [
            'in',
            'osm_id',
            'null'
          ]
        ],
      }, highlightedStreet);
      MapGL.on('mouseover', 'vector-highway-secondary-tertiary', this.unclusteredMouseover());
      MapGL.on('mouseout', 'vector-highway-secondary-tertiary', this.unclusteredMouseOut());
      MapGL.on('mouseover', 'vector-highway-minorx2', this.unclusteredMouseover());
      MapGL.on('mouseout', 'vector-highway-minorx2', this.unclusteredMouseOut());
      MapGL.on('mouseover', 'vector-highway-trunk', this.unclusteredMouseover());
      MapGL.on('mouseout', 'vector-highway-trunk', this.unclusteredMouseOut());
      MapGL.on('mouseover', 'vector-building-top', this.unclusteredMouseover());
      MapGL.on('mouseout', 'vector-building-top', this.unclusteredMouseOut());
    }

    getUserMarkers = async (markersId) => {
      const resp = await fetch(`${hostResolver(USER_MARKERS_URL)}?point_id=${markersId}`, {
        headers: {
          Authorization: AUTHORIZATION,
        },
      });
      if (!resp.ok) return null;
      const json = await resp.json();
      this.renderMarkers(json);
    };

    initUserLocation = () => {
      const { MapGL } = this.state;
      const userLocationAPI = new mapboxgl.GeolocateControl({
        positionOptions: {
          enableHighAccuracy: true,
        },
        trackUserLocation: true,
      });
      MapGL.addControl(userLocationAPI, 'top-left');
      this.userLocation = userLocationAPI;
    };

    renderMarkers = (markers) => {
      const { currentUser } = this.props;
      markers.points.map((marker) => {
        this.addUserMarker({
          id: marker.id,
          lng: marker.lng,
          lat: marker.lat,
          text: marker.text,
          // eslint-disable-next-line no-underscore-dangle
          parentId: markers._id,
          ownerId: markers.owner_id,
          defaultDrag: markers.owner_id === currentUser.user_id,
        });
      });
    };

    rmLayer = (layerName) => {
      const { MapGL } = this.state;
      if (MapGL.getLayer(layerName)) {
        MapGL.removeLayer(layerName);
        if (MapGL.getSource(layerName)) {
          MapGL.removeSource(layerName);
        }
      }
      if (MapGL.getSource(layerName)) {
        if (MapGL.getLayer(layerName)) {
          MapGL.removeLayer(layerName);
        }
        MapGL.removeSource(layerName);
      }
    };

    clearLayers = () => {
      DEFAULT_LAYERS.map((layerToRemove) => {
        this.rmLayer(layerToRemove);
      });
    };

    clearPublicRouteLayers = () => {
      new Array(20).fill(0).map((_, i) => {
        this.rmLayer(`publicRoute${i}`);
        this.rmLayer(`busStops${i}`);
      });
    };

    setOSMFilter = ({ layerId, osmIds }) => {
      const { MapGL } = this.state;
      MapGL.setFilter(layerId, [
        'all',
        [
          'in',
          'osm_id',
          ...osmIds
        ]
      ]);
    }

    resetOSMLayer = ({ layerId, hasId }) => {
      const { MapGL } = this.state;
      const layer = MapGL.getLayer(layerId);
      if (layer) {
        const filter = MapGL.getFilter(layerId);
        if (filter && filter[1][2] === hasId) {
          this.setOSMFilter({ layerId, osmIds: ['null'] });
          return true;
        }
      }
      return false;
    }

    reverseGeoCoding = ({
      id, type, companyId, branchId
    }) => {
      const { MapGL } = this.state;
      const { routingTools, mapMode } = this.props;
      if (mapMode) return;
      switch (type) {
        case 'poi': {
          if (companyId && branchId) {
            changeURLPage(`places/${companyId}/${branchId}`);
          }
          break;
        }
        case 'building': {
          actionGetBuildingByOSMID(id)
            .then((building) => {
              if (!building || !building.centroid) {
                return;
              }
              const text = `${building.street_name} ${building.number}`;
              const popupHolder = document.createElement('div');
              ReactDOM.render(
                <BuildingMarker
                  {...this.props}
                  streetName={text}
                  cityName={building.location}
                  markerObject
                  routingTools={routingTools}
                  getLocation={this.getLocation}
                  markerPosition={{ lng: building.centroid.lon, lat: building.centroid.lat }}
                  deleteMarker={() => this.buildingMarker.remove()}
                  toggleMarkerDrag={canDrag => this.buildingMarker.setDraggable(canDrag)}
                />,
                popupHolder
              );
              MapGL.easeTo({ center: [building.centroid.lon, building.centroid.lat] });
              this.buildingMarker.setLngLat([0, 0]);
              this.buildingMarker.getPopup().setDOMContent(popupHolder);
              this.buildingMarker.setLngLat([building.centroid.lon, building.centroid.lat]);
              this.buildingMarker.addTo(MapGL);
              this.buildingMarker.togglePopup();
            });
          break;
        }
        case 'transportation': {
          const isStreetReset = this.resetOSMLayer({ layerId: 'highlightedStreet', hasId: id });
          const isBuildReset = this.resetOSMLayer({ layerId: 'highlightedBuildings', hasId: id });
          if (isStreetReset && isBuildReset) {
            return;
          }
          actionGetStreedByOSMID(id)
            .then((street) => {
              if (!street || !street.pg_buildings || !street.osm_id || !street.osm_id.length) return;
              street.osm_id.unshift(id);
              street.pg_buildings.unshift(id);
              this.setOSMFilter({ layerId: 'highlightedStreet', osmIds: street.osm_id });
              this.setOSMFilter({ layerId: 'highlightedBuildings', osmIds: street.pg_buildings });
            });
          break;
        }
        default: {
          return null;
        }
      }
    }

    handleReversClick = (e) => {
      const { MapGL } = this.state;
      const features = MapGL.queryRenderedFeatures(e.point);
      console.log(features);
      this.buildingMarker.setLngLat([0, 0]);
      if (!features || !features.length) return;
      console.log(features);
      const topLayer = features.find(feature => !!feature.properties.osm_id);
      if (topLayer && topLayer.properties) {
        const topLayerOSMId = topLayer.properties.osm_id;
        const companyId = topLayer.properties.company_id;
        const branchId = topLayer.properties.branch_id;
        if (topLayerOSMId) {
          this.reverseGeoCoding({
            id: topLayerOSMId, type: topLayer.sourceLayer, companyId, branchId
          });
        }
      }
    }

    mapClickHandler = (e) => {
      const { lngLat } = e;
      const { mapMode, router } = this.props;
      this.handleReversClick(e);
      const {
        query: { markersId },
      } = router;
      switch (mapMode) {
        case 'markers': {
          this.addUserMarker({ ...lngLat, defaultDrag: true, parentId: markersId || null });
          break;
        }
        case 'ruler': {
          this.rulerOnClick(e);
          break;
        }
        default: {
          // const popup = document.getElementsByClassName('label3D');
          // if (popup.length) {
          //   popup[0].innerHTML = '';
          // }
        }
      }
    };

    modeSwitcher = async (mode) => {
      const { setsMapMode, mapMode } = this.props;
      switch (mode) {
        case 'markers': {
          setsMapMode(mode);
          this.clearUserMarker();
          this.rulerModeOff();
          break;
        }
        case 'ruler': {
          if (mapMode === 'ruler') {
            setsMapMode(mode);
            this.rulerModeOff();
          } else {
            await setsMapMode(mode);
            this.clearUserMarker();
            this.rulerLayerInit();
            this.rulerModeInit();
          }
          break;
        }
        default: {
          setsMapMode(mode);
          this.rulerModeOff();
        }
      }
    };

    clearUserMarker = () => {
      this.markerModeCache.filter((marker) => {
        marker.remove();
        return false;
      });
    };

    getLocation = () => {
      if (!this.userLocation) return;
      if (navigator.geolocation) {
        return new Promise((resolve, reject) => {
          navigator.geolocation.getCurrentPosition(
            position => resolve(position),
            // eslint-disable-next-line no-console
            (err) => { resolve(err); console.log(err); }
          );
        });
      }
    };

    getUserLocation = (noTrigger) => {
      // const { t } = this.props;
      if (!this.userLocation) return;
      this.userLocation.once('error', (e) => {
        // eslint-disable-next-line no-alert
        console.warn(e);
        // window.alert(t('Position could not be determined.'));
      });
      if (this.userLocation) {
        if (noTrigger) {
          return this.userLocation;
        }
        this.userLocation.trigger();
      }
      // let options = {
      //   enableHighAccuracy: true,
      //   maximumAge: 0
      // };
      // navigator.geolocation.getCurrentPosition(
      //   (location) => {
      //     console.log(location);
      //   },
      //   (err) => console.log(err),
      //   options
      // );
    };

    handleRouterPage = (page) => {
      const { MapGL } = this.state;
      const { urlParams } = this.props;
      if (page === 'point') {
        const currentView = MapGL.getCenter();
        this.addSimpleMarker(currentView);
      }
      if (page === 'polygon') {
        this.drawPolygon(urlParams);
      }
    }

    removeSimpleMarker = () => {
      if (SIMPLE_MARKER) {
        SIMPLE_MARKER.remove();
      }
    }

    addSimpleMarker = ({
      lng, lat
    }) => {
      const { MapGL } = this.state;
      const markerHolder = document.createElement('div');
      ReactDOM.render(<UserMarkerIcon {...this.props} />, markerHolder);
      SIMPLE_MARKER = new mapboxgl.Marker({
        radius: 7,
        element: markerHolder,
        fillOpacity: 0,
        draggable: false,
        offset: [0, 0],
        anchor: 'bottom',
        setZIndex: 9999999,
      });
      SIMPLE_MARKER.setLngLat([lng, lat]);
      SIMPLE_MARKER.addTo(MapGL);
    };

    getNear = async (lngLat, raw) => {
      const { t } = this.props;
      try {
        const { lng, lat } = lngLat;
        const resp = await fetch(`${hostResolver(GET_NEAR_URL)}?lat=${lat}&lon=${lng}`, {
          headers: {
            Authorization: AUTHORIZATION
          }
        });
        const nearMe = await resp.json();
        if (raw) {
          return nearMe;
        }
        if (nearMe.building) {
          return `${nearMe.building.street_name}, ${nearMe.building.number}`;
        }
        if (nearMe.street) {
          return `${nearMe.street.location}, ${nearMe.street.name}`;
        }
        if (nearMe.company && nearMe.company.length) {
          return nearMe.company[0].brand || nearMe.company[0].title;
        }
        if (nearMe.city) {
          return `${nearMe.city.name}`;
        }
        return t('Out of Boundary Paths');
      } catch (err) {
        // eslint-disable-next-line no-console
        console.log(err);
      }
    };

    addUserMarker = ({
      lng, lat, text, parentId, ownerId, id, defaultDrag
    }) => {
      const { MapGL } = this.state;
      const {
        currentUser, togglerShareModal, router: { query }
      } = this.props;
      const { embed, hidePopup } = query;
      let popup = null;
      let marker = null;
      const markerHolder = document.createElement('div');
      const popupHolder = document.createElement('div');
      ReactDOM.render(
        <UserMarker
          {...this.props}
          parentId={parentId}
          markerObject
          ownerId={ownerId}
          markerId={id}
          markerText={text}
          toggleShareModal={togglerShareModal}
          currentUserId={currentUser.user_id}
          markerPosition={{ lng, lat }}
          deleteMarker={() => marker.remove()}
          toggleMarkerDrag={canDrag => marker.setDraggable(canDrag)}
        />,
        popupHolder
      );
      ReactDOM.render(<UserMarkerIcon {...this.props} />, markerHolder);
      popup = new mapboxgl.Popup({
        offset: { bottom: [0, -36] },
        closeOnClick: true,
        className: 'user-marker-popup'
      });
      popup.setDOMContent(popupHolder);
      marker = new mapboxgl.Marker({
        radius: 7,
        element: markerHolder,
        fillOpacity: 0,
        draggable: defaultDrag,
        offset: [0, 0],
        anchor: 'bottom',
        setZIndex: 9999999,
      });
      marker.setLngLat([lng, lat]);
      marker.setPopup(popup);
      marker.addTo(MapGL);
      if (!(embed && hidePopup)) {
        marker.togglePopup();
      }
      markerHolder.addEventListener('click', (e) => {
        e.stopPropagation();
        marker.togglePopup();
      });
      // ! TODO: scary dangerous code (need refactor)
      // TODO: scary dangerous code (need refactor)
      marker.on('dragend', ({ target }) => {
        marker.remove();
        const newLngLat = target.getLngLat();
        this.addUserMarker({
          lng: newLngLat.lng,
          lat: newLngLat.lat,
          text,
          parentId,
          ownerId,
          id,
          defaultDrag,
        });
      });
      this.markerModeCache.push(marker);
    };

    drawPolygon = ({ coords }) => {
      const { MapGL } = this.state;
      const coordinates = JSON.parse(coords);

      const bounds = new mapboxgl.LngLatBounds();
      coordinates.map((coord) => {
        bounds.extend(coord);
      });

      MapGL.addLayer({
        id: 'polygon999',
        type: 'fill',
        source: {
          type: 'geojson',
          data: {
            type: 'Feature',
            geometry: {
              type: 'Polygon',
              coordinates: [coordinates]
            }
          }
        },
        layout: {},
        paint: {
          'fill-color': '#ff7201',
          'fill-opacity': 0.4,
          'fill-outline-color': '#000',
        }
      });

      MapGL.fitBounds(bounds, {
        linear: true,
        padding: {
          top: 100,
          left: 150,
          bottom: 50,
          right: 20,
        },
      });
    }

    drawOSRMRoute = (osrmRouteResp, name) => {
      const geoPath = [];
      if (!osrmRouteResp) return geoPath;
      osrmRouteResp.routes.map((route) => {
        route.legs.map((leg) => {
          leg.steps.map((step) => {
            step.intersections.map((intersection) => {
              geoPath.push(intersection.location);
            });
          });
        });
      });
      this.drawLine({
        isDashed: true,
        name,
        data: geoPath,
        noBoundsFit: true,
        belowLayer: 'directions-route-line-alt-casing',
      });
      return geoPath;
    };

    drawPublicRoute = (route) => {
      // const { MapGL } = this.props;
      const boundsFrom = [];
      if (!route.routing) return;
      this.clearLayers();
      this.clearPublicRouteLayers();
      const r1 = this.drawOSRMRoute(route.osrm_route_from, 'publicRouteFrom');
      const r2 = this.drawOSRMRoute(route.osrm_route_to, 'publicRouteTo');
      const r3 = this.drawOSRMRoute(route.osrm_route_transfer, 'publicRouteTransfer');
      route.routing.map((pr, index) => {
        boundsFrom.push(pr.geo_path);
        this.drawLine({
          name: `publicRoute${index}`,
          data: pr.geo_path,
          noBoundsFit: true,
          belowLayer: 'directions-route-line-alt-casing',
          showDirection: true
        });
        if (pr.bus_stop) {
          this.drawBusStops(pr.bus_stop, `busStops${index}`);
          // MapGL.on('mouseenter', `busStops${index}`, (e) => {
          //   MapGL.getCanvas().style.cursor = 'pointer';
          //   const name = e.features[0].properties.name
          //   console.log(name)
          // });
          // MapGL.on('mouseleave', `busStops${index}`, (e) => {
          //   MapGL.getCanvas().style.cursor = '';
          // });
        }
      });
      this.setBounds(boundsFrom.concat([r1, r2, r3]));
    };

    setBounds = (boundsFrom, bearing) => {
      const { MapGL } = this.state;
      const bounds = this.getBounds(boundsFrom);
      const options = {
        padding: 60,
      };
      if (bearing) {
        options.bearing = bearing;
      }
      MapGL.fitBounds(bounds, options);
    };

    drawBusStops = (stops, layerName) => {
      const { MapGL } = this.state;
      const { i18n, router } = this.props;
      const { language } = i18n;
      const {
        query: { viewType },
      } = router;
      const belowLayer = viewType === 'sateliteStyle' ? 'raster-highway-name-path' : 'vector-highway-name-path';
      BUS_STOPS.features = [];
      MapGL.addSource(layerName, {
        type: 'geojson',
        data: BUS_STOPS,
      });
      MapGL.addLayer({
        id: layerName,
        type: 'circle',
        source: layerName,
        paint: {
          'circle-radius': 3,
          'circle-color': '#ffffff',
          'circle-stroke-color': theme.colors.accent,
          'circle-stroke-width': 2,
        },
        filter: ['in', '$type', 'Point'],
      }, belowLayer);
      stops.map((stop) => {
        const point = {
          type: 'Feature',
          geometry: {
            type: 'Point',
            coordinates: stop.route_point,
          },
          properties: {
            name: stop.title[language],
          },
        };
        BUS_STOPS.features.push(point);
      });
      MapGL.getSource(layerName).setData(BUS_STOPS);
    };

    drawRoute = async (routeId) => {
      const { MapGL } = this.state;
      if (this.lastRoute === routeId || MapGL.mock) return;
      const { fetchRoute, mapViewType } = this.props;
      // eslint-disable-next-line max-len
      const belowLayer = mapViewType === 'sateliteStyle' ? 'raster-highway-name-path' : 'vector-highway-name-path';
      const routeResp = await fetchRoute(routeId);
      const route = routeResp.payload;
      this.lastRoute = routeId;
      this.drawLine({
        data: route.geom, showDirection: true, belowLayer,
      });
    };

    getBounds = (arr) => {
      let coords = [...arr];
      if (arr && arr[0] && arr[0][0] && arr[0][0][0]) {
        coords = arr.reduce((acc, val) => acc.concat(val), []);
      }
      return coords.reduce(
        (bounds, coord) => bounds.extend(coord),
        new mapboxgl.LngLatBounds(coords[0], coords[0])
      );
    };

    drawLine = ({
      name, isDashed, color, width, data, center, noBoundsFit, belowLayer = '', showDirection
    }) => {
      if (!data) {
        console.warn('data:', data);
        return;
      }
      const defaultLayerName = 'mapLine';
      let layerName = defaultLayerName;

      if (name) {
        layerName = name;
      }
      const { MapGL } = this.state;
      const paint = {
        'line-color': color || theme.colors.accent,
        'line-width': width || 5,
      };
      const reverseGeom = data.map((arr) => {
        if (arr.constructor === Object) {
          return [arr.lon, arr.lat];
        }
        if (arr.constructor === Array) {
          return [arr[0], arr[1]];
        }
        return arr.map(geom => [geom.lon, geom.lat]);
      });
      if (isDashed) {
        paint['line-dasharray'] = [0, 1.5];
      }
      if (showDirection) {
        paint['line-width'] = {
          stops: [
            [14, 4],
            [15, 4],
            [16, 4],
            [16.5, 6],
            [16.8, 6],
            [17, 6],
            [17.5, 6.5],
            [17.8, 7],
            [18, 7],
            [18.5, 7],
            [19, 7]
          ]
        };
        paint['line-pattern'] = [
          'step', ['zoom'],
          'routes-dir-s', 16.5, // these images are in map sprite
          'routes-dir', 16.6, // these images are in map sprite
          'routes-dir' // these images are in map sprite
        ];
      }
      const addLayer = (underLayer) => {
        this.rmLayer(layerName);
        MapGL.addSource(layerName, {
          type: 'geojson',
          data: {
            type: 'Feature',
            properties: {},
            geometry: {
              type: 'LineString',
              coordinates: reverseGeom,
            },
          },
        });
        MapGL.addLayer(
          {
            id: layerName,
            type: 'line',
            source: layerName,
            layout: {
              'line-join': 'round',
              'line-cap': 'round',
            },
            paint,
          },
          underLayer
        );
      };
      if (MapGL.isStyleLoaded()) {
        addLayer(belowLayer);
      } 
      MapGL.once('data', (e) => {
        let underLayer = belowLayer;
        if (['raster-highway-name-path', 'vector-highway-name-path'].indexOf(belowLayer) > -1) {
          if (e && e.style._order && e.style._order[0]) {
            if (e.style._order[0].indexOf('raster') > -1) {
              underLayer = 'raster-highway-name-path';
            }
            if (e.style._order[0].indexOf('vector') > -1) {
              underLayer = 'vector-highway-name-path';
            }
            addLayer(underLayer);
          }
        } else {
          addLayer(belowLayer);
        }
      });

      if (noBoundsFit) return;
      this.setBounds(reverseGeom);
    };

    drawMultiLine = ({
      name, color, width, data, center
    }) => {
      this.clearLayers();
      this.rmLayer(name);
      const { MapGL } = this.state;
      const reverseGeom = data.map(arr => arr.map(geom => [geom.lon, geom.lat]));
      MapGL.addLayer({
        id: 'mapMultiLine',
        type: 'line',
        source: {
          type: 'geojson',
          data: {
            type: 'Feature',
            properties: {},
            geometry: {
              type: 'MultiLineString',
              coordinates: reverseGeom,
            },
          },
        },
        layout: {
          'line-join': 'round',
          'line-cap': 'round',
        },
        paint: {
          'line-color': color || theme.colors.accent,
          'line-width': width || 5,
        },
      });
      this.setBounds(reverseGeom, reverseGeom.length);
    };

    rulerModeOff = () => {
      const { MapGL } = this.state;
      if (MapGL.getSource('geojsonDistancePath') && MapGL.getLayer('measure-points')) {
        MapGL.removeLayer('measure-points');
        MapGL.removeLayer('measure-lines');
        MapGL.removeSource('geojsonDistancePath');
        LINESTRING.geometry.coordinates = [];
        GEOJSON.features = [];
      }
      if (this.markerStart && this.markerEnd) {
        this.markerEnd.remove();
        this.markerStart.remove();
      }
    };

    rulerLayerInit = () => {
      const { MapGL } = this.state;
      if (MapGL.getSource('geojsonDistancePath') && MapGL.getLayer('measure-points')) {
        MapGL.removeLayer('measure-points');
        MapGL.removeLayer('measure-lines');
        MapGL.removeSource('geojsonDistancePath');
        LINESTRING.geometry.coordinates = [];
        GEOJSON.features = [];
      }

      MapGL.addSource('geojsonDistancePath', {
        type: 'geojson',
        data: GEOJSON,
      });

      MapGL.addLayer({
        id: 'measure-points',
        type: 'circle',
        source: 'geojsonDistancePath',
        paint: {
          'circle-radius': ['step', ['get', 'count'], 0, 1, 5, 2, 5],
          'circle-color': '#F56400',
        },
        filter: ['in', '$type', 'Point'],
      });
      MapGL.addLayer({
        id: 'measure-lines',
        type: 'line',
        source: 'geojsonDistancePath',
        layout: {
          'line-cap': 'round',
          'line-join': 'round',
        },
        paint: {
          'line-color': '#F56400',
          'line-width': 2.5,
          'line-dasharray': [2, 2],
        },
        filter: ['in', '$type', 'LineString'],
      });
    };

    rulerLayerRemove = (e) => {
      e.stopPropagation();
      const { MapGL } = this.state;
      if (MapGL.getSource('geojsonDistancePath')) {
        if (this.markerStart) {
          this.markerStart.setLngLat([0, 0]);
        }
        if (this.markerEnd) {
          this.markerEnd.setLngLat([0, 0]);
        }
        this.rulerLayerInit();
      }
    };

    rulerModeInit = () => {
      const { MapGL } = this.state;
      const iconStart = document.createElement('div');
      const iconEnd = document.createElement('div');

      ReactDOM.render(<StartIcon />, iconStart);
      ReactDOM.render(<EndIcon removePath={e => this.rulerLayerRemove(e)} />, iconEnd);

      this.markerStart = new mapboxgl.Marker({
        radius: 7,
        className: 'marker-start',
        element: iconStart,
        draggable: true,
        anchor: 'bottom',
        offset: [6, 4],
        setZIndex: 9999999,
      })
        .setLngLat([0, 0])
        .addTo(MapGL);

      this.markerEnd = new mapboxgl.Marker({
        radius: 7,
        className: 'marker-end',
        element: iconEnd,
        draggable: true,
        anchor: 'bottom-left',
        offset: [-9, 13],
        setZIndex: 9999999,
      })
        .setLngLat([0, 0])
        .addTo(MapGL);

      MapGL.on('mousemove', e => this.rulerPointCursor(e));
      MapGL.on('mousedown', 'measure-points', e => this.rulerPointsOnStart(e));
      this.markerEnd.on('dragend', () => this.rulerOnUp());
      this.markerStart.on('dragend', () => this.rulerOnUp());
      this.markerEnd.on('drag', e => this.rulerOnMove(e, 'marker-end'));
      this.markerStart.on('drag', e => this.rulerOnMove(e, 'marker-start'));
    };

    rulerOnClick = (e) => {
      const { MapGL } = this.state;
      const { t } = this.props;
      if (!this.markerStart && !this.markerEnd) return;

      const tempPoints = [];
      const features = MapGL.queryRenderedFeatures(e.point, { layers: ['measure-points'] });

      if (GEOJSON.features.length > 1) GEOJSON.features.pop();
      if (!features.length) this.markerStart.setLngLat([e.lngLat.lng, e.lngLat.lat]);
      if (features.length) {
        const id = features[0].properties.id;
        GEOJSON.features = GEOJSON.features.filter(point => point.properties.id !== id);
        GEOJSON.features = GEOJSON.features.map((point, index) => {
          const newIndexPoint = { ...point };
          newIndexPoint.properties.count = index;
          return point;
        });
      } else {
        const point = {
          type: 'Feature',
          geometry: {
            type: 'Point',
            coordinates: [e.lngLat.lng, e.lngLat.lat],
          },
          properties: {
            id: String(new Date().getTime()),
            count: GEOJSON.features.length,
            distance: 0,
          },
        };
        GEOJSON.features.push(point);
      }

      if (GEOJSON.features.length > 1) {
        LINESTRING.geometry.coordinates = GEOJSON.features.map(point => point.geometry.coordinates);
        GEOJSON.features.map((feature) => {
          const point = feature;
          if (GEOJSON.features.indexOf(point) === GEOJSON.features.length - 1) {
            const distance = turf.lineDistance(LINESTRING) * 1000;
            this.markerEnd.getElement().querySelector('#distance-measure').textContent = getMetersKm(
              distance,
              t,
              2
            );
            this.markerEnd.setLngLat(point.geometry.coordinates);
          }
          if (GEOJSON.features.indexOf(point) === 0) {
            this.markerStart.setLngLat(point.geometry.coordinates);
            point.properties.distance = 0;
          } else {
            point.properties.distance = -1;
          }
          tempPoints.push(point);
        });
        GEOJSON.features = tempPoints;
        GEOJSON.features.push(LINESTRING);
      }
      return MapGL.getSource('geojsonDistancePath').setData(GEOJSON);
    };

    rulerOnMove = (event, movedMarker) => {
      const { MapGL } = this.state;
      const { mapMode, t } = this.props;
      if (mapMode !== 'ruler') return;
      const features = MapGL.queryRenderedFeatures(event.point, {
        layers: ['measure-points'],
      });
      // eslint-disable-next-line no-underscore-dangle
      const coords = event.target._lngLat;
      MapGL.getCanvas().style.cursor = 'grabbing';
      if (features.length) {
        GEOJSON.features = GEOJSON.features.filter(point => point.geometry.type !== 'LineString');
      }
      if (movedMarker === 'marker-start') {
        GEOJSON.features[0].geometry.coordinates = [coords.lng, coords.lat];
      }
      if (movedMarker === 'marker-end') {
        GEOJSON.features[GEOJSON.features.length - 1].geometry.coordinates = [coords.lng, coords.lat];
      }
      if (GEOJSON.features.length > 1) {
        LINESTRING.geometry.coordinates = GEOJSON.features.map(point => point.geometry.coordinates);
        const distance = turf.lineDistance(LINESTRING) * 1000;
        this.markerEnd.getElement().querySelector('#distance-measure').textContent = getMetersKm(
          distance,
          t,
          2
        );
        GEOJSON.features.push(LINESTRING);
      }
      return MapGL.getSource('geojsonDistancePath').setData(GEOJSON);
    };

    rulerOnUp = () => {
      const { MapGL } = this.state;
      MapGL.getCanvas().style.cursor = 'crosshair';
    };

    rulerPointsOnStart = (e) => {
      const { MapGL } = this.state;
      this.mesurePoints = MapGL.queryRenderedFeatures(e.point, { layers: ['measure-points'] })[0];
      e.preventDefault();
      MapGL.on('mousemove', this.pointsOnMove);
      MapGL.once('mouseup', this.pointsOnUp);
    };

    pointsOnMove = (e) => {
      const { MapGL } = this.state;
      const { t, mapMode } = this.props;
      const { properties } = this.mesurePoints;
      if (mapMode !== 'ruler') return;

      // eslint-disable-next-line no-underscore-dangle
      const coords = e.lngLat;
      MapGL.getCanvas().style.cursor = 'grabbing';

      if (this.mesurePoints) {
        GEOJSON.features[properties.count].geometry.coordinates = [coords.lng, coords.lat];
      }

      if (GEOJSON.features.length > 1) {
        GEOJSON.features = GEOJSON.features.filter(point => point.geometry.type !== 'LineString');
        LINESTRING.geometry.coordinates = GEOJSON.features.map(point => point.geometry.coordinates);
        const distance = turf.lineDistance(LINESTRING) * 1000;
        this.markerEnd.getElement().querySelector('#distance-measure').textContent = getMetersKm(
          distance,
          t,
          2
        );
        GEOJSON.features.push(LINESTRING);
      }
      return MapGL.getSource('geojsonDistancePath').setData(GEOJSON);
    };

    pointsOnUp = () => {
      const { MapGL } = this.state;
      MapGL.getCanvas().style.cursor = '';
      MapGL.off('mousemove', this.pointsOnMove);
    }

    rulerPointCursor = (e) => {
      const { MapGL } = this.state;
      const { mapMode } = this.props;
      if (mapMode !== 'ruler') return;
      const features = MapGL.queryRenderedFeatures(e.point, { layers: ['measure-points'] });
      if (features.length > 0) {
        MapGL.getCanvas().style.cursor = features[0].properties.Name !== null ? 'pointer' : '';
      } else {
        MapGL.getCanvas().style.cursor = 'crosshair';
      }
    };

    setPlaceMarker = (place, noFly) => {
      const { MapGL } = this.state;
      if (MapGL.mock) return;
      const markerComponent = document.createElement('div');
      if (!this.placeMarker) {
        this.placeMarker = new mapboxgl.Marker({
          radius: 7,
          element: markerComponent,
          fillOpacity: 0,
          draggable: false,
          offset: [-17, 1.8],
          anchor: 'left',
          setZIndex: 9999999,
        })
          .setLngLat([0, 0])
          .addTo(MapGL);
      }
      ReactDOM.render(<MapPlaceMarker />, markerComponent, () => {
        this.placeMarkerCoords(place, noFly);
      });
    };

    getMapGL = () => {
      const { MapGL } = this.state;
      return MapGL;
    }

    placeMarkerCoords = (place, noFly) => {
      const { MapGL } = this.state;
      const {
        point: { lon, lat },
        brand,
        zoom
      } = place;
      this.placeMarker.setLngLat([lon, lat]);
      if (!noFly) {
        MapGL.flyTo({
          center: [lon, lat],
          zoom: zoom || 15.5,
        });
      }
      if (this.placeMarker.getElement().querySelector('.MarkerAddres')) {
        this.placeMarker.getElement().querySelector('.MarkerAddres').textContent = brand;
      }
    };

    clearMapPlaceMarker = () => {
      if (!this.placeMarker) return;
      this.placeMarker.setLngLat([0, 0]);
    };

    removeRoutingOrigin = () => {
      const {
        routingTools, emptyRoutesStore
      } = this.props;
      this.clearLayers();
      this.clearPublicRouteLayers();
      routingTools.removeOrigin();
      emptyRoutesStore('origin');
      addQueryStringToUrl({ a: null }, true);
    };

    removeRoutingDestination = () => {
      const {
        routingTools, emptyRoutesStore
      } = this.props;
      this.clearLayers();
      this.clearPublicRouteLayers();
      routingTools.removeDestination();
      emptyRoutesStore('destination');
      addQueryStringToUrl({ b: null }, true);
    };

    removeRoutes = (close, effect) => {
      const {
        router, routingTools, emptyRoutesStore, actionClearObject
      } = this.props;
      this.clearLayers();
      this.clearPublicRouteLayers();
      routingTools.removeRoutes();
      emptyRoutesStore();
      if (effect) return;
      if (close) {
        actionClearObject();
        replaceURLPage({ page: '', clearSearch: true }, false);
      } else {
        router.back();
        // changeURLPage('');
        addQueryStringToUrl({ a: null, b: null, routingMode: null }, true);
      }
    };

    clustersClick = () => (e) => {
      const { MapGL } = this.state;
      const features = MapGL.queryRenderedFeatures(e.point, { layers: ['clusters'] });
      const clusterId = features[0].properties.cluster_id;
      MapGL.getSource('placeCluster').getClusterExpansionZoom(clusterId, (err, zoom) => {
        if (err) return;
        MapGL.flyTo({
          center: features[0].geometry.coordinates,
          zoom,
        });
      });
    };

    unclusteredMouseover = () => (e) => {
      const { MapGL } = this.state;
      MapGL.getCanvas().style.cursor = 'pointer';
    };

    unclusteredMouseOut = () => (e) => {
      const { MapGL } = this.state;
      MapGL.getCanvas().style.cursor = '';
    };

    unclusteredClick = () => async (e) => {
      const { router, emptySearchResults, actionToggleMapShow } = this.props;
      const {
        query: { categoryUrl, branchId, companyId },
      } = router;
      const { properties } = e.features[0];
      const isBranch = properties.braches_count > 1 || properties.branches_count > 1;
      const urlToOpen = `places/${categoryUrl ? `${categoryUrl}/` : ''}${properties.company_id}${
        !isBranch && properties.branch_id ? `/${properties.branch_id}` : ''
      }`;
      emptySearchResults();
      if (branchId && companyId) {
        replaceURLPage({ page: urlToOpen });
        return;
      }
      if (branchId || companyId) {
        await changeURLPage(urlToOpen);
        return;
      }
      await changeURLPage(urlToOpen, true);
      actionToggleMapShow(false);
    };

    clearCluster = () => {
      const { MapGL } = this.state;
      if (MapGL.getSource('placeCluster')) {
        MapGL.removeLayer('clusters');
        MapGL.removeLayer('unclustered-point');
        MapGL.removeSource('placeCluster');
      }
    };

    filterClusterClear = (companyId) => {
      const { MapGL } = this.state;
      let filteredClusterBranches = null;
      if (CLUSTER_COLLECTION.features) {
        filteredClusterBranches = CLUSTER_COLLECTION.features;
      }
      if (MapGL.getSource('placeCluster')) {
        MapGL.getSource('placeCluster').setData({
          type: 'FeatureCollection',
          features: filteredClusterBranches,
        });
      }
    };

    filterClusterByCompany = (companyId) => {
      const { MapGL } = this.state;
      // const branchesCluster = MapGL.getSource('placeCluster').serialize();
      // const branchesClusterFeatures = branchesCluster.data.features;
      let filteredClusterBranches = null;
      const bounds = new mapboxgl.LngLatBounds();
      if (CLUSTER_COLLECTION.features) {
        filteredClusterBranches = CLUSTER_COLLECTION.features.filter(
          (feature) => {
            if (feature.properties.company_id === companyId) {
              bounds.extend(feature.geometry.coordinates);
              return true;
            }
            return false;
          }
        );
      }
      if (!MapGL.getSource('placeCluster') || !Object.keys(bounds).length) return;
      MapGL.getSource('placeCluster').setData({
        type: 'FeatureCollection',
        features: filteredClusterBranches,
      });
      MapGL.fitBounds(bounds, {
        linear: true,
        padding: {
          top: 150,
          left: 150,
          bottom: 20,
          right: 20,
        },
      });
    };

    initCluster = async ({ items, companyId }) => {
      const {
        state: { MapGL },
      } = this;
      if (!MapGL) return;
      const bounds = new mapboxgl.LngLatBounds();
      // const companyWithBranches = items
      //   .filter(item => item.braches_count > 1)
      //   .map(item => `${GET_COMPANY_BRANCHES_URL}?id=${item.company_id}&take=90`);

      // const promiseBranch = await Promise.all(companyWithBranches.map(item => fetch(item)));
      // const promiseResolved = await Promise.all(promiseBranch.map(item => item.json()));
      // const branches = items
      //   .reduce((acc, branch) => {
      //     acc.push(branch.points);
      //     return acc;
      //   }, [])
      //   .flat(1);
      // if (!items) return;
      const points = items.reduce((acc, place) => {
        if (!place.point) return acc;
        bounds.extend([place.point.lon, place.point.lat]);
        acc.push({
          type: 'Feature',
          geometry: {
            type: 'Point',
            coordinates: [place.point.lon, place.point.lat],
          },
          properties: {
            ...place,
          },
        });
        return acc;
      }, []);
      CLUSTER_COLLECTION.features = points;
      this.clearCluster();
      MapGL.addSource('placeCluster', {
        type: 'geojson',
        data: {
          type: 'FeatureCollection',
          features: points,
        },
        cluster: true,
        clusterMaxZoom: 15,
        clusterRadius: 30,
      });
      MapGL.addLayer({
        id: 'clusters',
        type: 'circle',
        source: 'placeCluster',
        filter: ['all', ['has', 'point_count']],
        paint: {
          'circle-stroke-width': 2,
          'circle-stroke-color': '#Fff',
          'circle-color': '#FF7201',
          'circle-radius': 5,
        },
      });
      MapGL.addLayer({
        id: 'unclustered-point',
        type: 'symbol',
        source: 'placeCluster',
        filter: ['all', ['!has', 'point_count']],
        layout: {
          'icon-image': 'unclusterPoint',
          'icon-size': 1,
          'icon-allow-overlap': true,
        },
      });
      // MapGL.flyTo({ center: [28.825643, 47.027112], zoom: 12.14 });
      // TO do refactor fit bounds! Get error LngLat (NaN, NaN)
      // const userLoc = await this.getLocation();
      // if (userLoc && userLoc.coords) {
      //   const { latitude, longitude } = userLoc.coords;
      //   MapGL.flyTo({ center: [longitude, latitude], zoom: 10.00 });
      // } else {
      if (!bounds._ne) return;
      MapGL.fitBounds(bounds, {
        linear: true,
        padding: {
          top: 100,
          left: 150,
          bottom: 50,
          right: 20,
        },
      });
      // }
      MapGL.loadImage('/static/images/unclusteredMarker.png', (_, image) => {
        if (!MapGL.hasImage('unclusterPoint')) {
          MapGL.addImage('unclusterPoint', image);
        }
      });
    };

    addMobilePitchGesture = (mbMap) => {
      const minDiffX = 70; // min x distance to recognize pitch gesture
      const maxDiffY = 100; // max y distance to recognize pitch gesture
      const minDiff = 30; // min distance to recognize zoom gesture
      const delay = 160; // delay for pitch, in case it's a zoom gesture
    
      let dpPoint;
      let dpPitch;
      let startTiming;
      let startDistance;
      let startEventData;
    
      mbMap
        .on('touchstart', (data) => {
          if (data.points.length === 2) {
            const diffY = data.points[0].y - data.points[1].y;
            const diffX = data.points[0].x - data.points[1].x;
            if (Math.abs(diffX) >= minDiffX && Math.abs(diffY) <= maxDiffY) {
              data.originalEvent.preventDefault(); // prevent browser refresh on pull down
              mbMap.touchZoomRotate.disable(); // disable native touch controls
              mbMap.dragPan.disable();
              dpPoint = data.point;
              dpPitch = mbMap.getPitch();
              startTiming = Date.now();
              startDistance = Math.hypot(diffX, diffY);
              startEventData = data;
            }
          }
        })
        .on('touchmove', (data) => {
          if (dpPoint !== undefined && dpPitch !== undefined) {
            data.preventDefault();
            data.originalEvent.preventDefault();
    
            const diffY = data.points[0].y - data.points[1].y;
            const diffX = data.points[0].x - data.points[1].x;
            const distance = Math.hypot(diffX, diffY);
    
            if (Math.abs(distance - startDistance) >= minDiff) {
              if (dpPoint) {
                mbMap.touchZoomRotate.enable();
                mbMap.dragPan.enable();
                mbMap.touchZoomRotate.onStart(
                  Date.now() - startTiming >= delay
                    ? data.originalEvent
                    : startEventData.originalEvent
                );
              }
              dpPoint = undefined;
              return;
            }
    
            if (Date.now() - startTiming >= delay) {
              const diff = (dpPoint.y - data.point.y) * 0.5;
              mbMap.setPitch(dpPitch + diff);
            }
          }
        })
        .on('touchend', () => {
          if (dpPoint) {
            mbMap.touchZoomRotate.enable();
            mbMap.dragPan.enable();
          }
          dpPoint = undefined;
        })
        .on('touchcancel', () => {
          if (dpPoint) {
            mbMap.touchZoomRotate.enable();
            mbMap.dragPan.enable();
          }
          dpPoint = undefined;
        });
    }

    render() {
      const { routingTools } = this.props;
      return (
        <MapToolsCtx.Provider
          value={{
            routingTools,
            mapTools: {
              getMapGL: this.getMapGL,
              getUserLocation: this.getUserLocation,
              removeSimpleMarker: this.removeSimpleMarker,
              getNear: this.getNear,
              addSimpleMarker: this.addSimpleMarker,
              getLocation: this.getLocation,
              removeRoutes: this.removeRoutes,
              removeRoutingOrigin: this.removeRoutingOrigin,
              removeRoutingDestination: this.removeRoutingDestination,
              drawLine: this.drawLine,
              drawMultiLine: this.drawMultiLine,
              modeSwitcher: this.modeSwitcher,
              drawRoute: this.drawRoute,
              drawPublicRoute: this.drawPublicRoute,
              clearLayers: this.clearLayers,
              setPlaceMarker: this.setPlaceMarker,
              clearMapPlaceMarker: this.clearMapPlaceMarker,
              filterClusterByCompany: this.filterClusterByCompany,
              filterClusterClear: this.filterClusterClear,
              initCluster: this.initCluster,
              clearCluster: this.clearCluster,
            },
          }}
        >
          <Component {...this.props} />
        </MapToolsCtx.Provider>
      );
    }
  }

  WithMapTools.defaultProps = {
    street: null,
    showLevelBtns: null
  };

  WithMapTools.propTypes = {
    tReady: PropTypes.bool.isRequired,
    MapGL: PropTypes.object.isRequired,
    currentUser: PropTypes.object.isRequired,
    i18n: PropTypes.object.isRequired,
    street: PropTypes.object,
    showLevelBtns: PropTypes.any,
    router: PropTypes.object.isRequired,
    routingTools: PropTypes.object.isRequired,
    mapMode: PropTypes.string.isRequired,
    t: PropTypes.func.isRequired,
    togglerShareModal: PropTypes.func.isRequired,
    emptySearchResults: PropTypes.func.isRequired,
    actionToggleMapShow: PropTypes.func.isRequired,
    setsMapMode: PropTypes.func.isRequired,
    fetchRoute: PropTypes.func.isRequired,
    emptyRoutesStore: PropTypes.func.isRequired,
    actionClearObject: PropTypes.func.isRequired,
    actionInitOnMount: PropTypes.func.isRequired,
    actionToggleLevelBtns: PropTypes.func.isRequired,
    canInitOnMount: PropTypes.bool.isRequired,
    urlParams: PropTypes.object.isRequired,
    mapViewType: PropTypes.string.isRequired
  };

  const mapStateToProps = state => ({
    mapMode: state.map.mode,
    currentUser: state.map.user,
    showLevelBtns: state.map.showLevelBtns,
    canInitOnMount: state.map.canInitToolsOnMount,
    mapViewType: state.tileStyles.mapViewType
  });

  const mapDispatchToProps = dispatch => ({
    emptySearchResults: () => dispatch(clearSearchResults()),
    emptyRoutesStore: waypoint => dispatch(clearRoutesStore(waypoint)),
    setsMapMode: mode => dispatch(setMapMode(mode)),
    actionToggleMapShow: show => dispatch(toggleMapShow(show)),
    togglerShareModal: isOpened => dispatch(toggleShareModal(isOpened)),
    actionToggleLevelBtns: lvls => dispatch(toggleLevelBtns(lvls)),
    fetchRoute: routeId => dispatch(getRoute(routeId)),
    actionClearObject: () => dispatch(clearObject()),
    actionInitOnMount: canInit => dispatch(initOnMount(canInit))
  });

  return withMap(
    connect(
      mapStateToProps,
      mapDispatchToProps
    )(withTranslation()(withRouter(withMapRouting(WithMapTools))))
  );
};

export default withMapTools;
