import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import { MapToolsCtx } from '@/src/contexts';

const Button = styled.button`
  padding: 9px 8px;
  display: inline-block;
  color: rgba(0, 0, 0, 0.54);
  &:hover,
  &.active {
    color: ${({ theme }) => theme.colors.accent};
  }
`;

const ToolIcon = styled.span`
  font-size: 1.8rem;
  line-height: 0;
`;

const RulerTool = ({ mapMode, t }) => (
  <MapToolsCtx.Consumer>
    {({ mapTools }) => (
      <Button
        data-rh={t('Ruler')}
        className={`${mapMode === 'ruler' && 'active'}`}
        onClick={() => mapTools.modeSwitcher('ruler')}
      >
        <ToolIcon className='icon-straighten' />
      </Button>
    )}
  </MapToolsCtx.Consumer>
);

RulerTool.propTypes = {
  mapMode: PropTypes.string.isRequired,
  t: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({
  mapMode: state.map.mode,
});

export default connect(mapStateToProps)(withTranslation()(RulerTool));
