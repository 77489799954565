import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { socialShare } from '@/src/helpers';

const Wrapper = styled.div`
  padding: 0 30px 30px;
`;

const ModalInputLink = styled.input`
  height: 36px;
  width: 100%;
  border-radius: 18px;
  background-color: #f5f5f5;
  color: rgba(0, 0, 0, 0.87);
  font-size: 1.3rem;
  line-height: 15px;
  border: 0;
  padding: 10px 16px;
  margin-bottom: 10px;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  &:focus {
    outline: none;
  }
`;

const SocialWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  margin: 10px 0 0;
`;

const SocialLink = styled.a`
  display: inline-block;
  position: relative;
  height: 36px;
  width: 100px;
  border-radius: 18px;
  background-color: ${({ color }) => color};
  text-align: center;
`;

const SocialIcon = styled.span`
  vertical-align: middle;
  margin: 0 20px;
  font-size: 1.8rem;
  color: #fff;
  top: 6px;
  position: relative;
`;

const HtmlSwitcher = styled.button`
  color: rgba(0, 0, 0, 0.87);
  font-size: 1.3rem;
  line-height: 34px;
  padding: 0 18px;
  display: inline-block;
  height: 35px;
  border: 1px solid rgba(151,151,151,.2);
  border-radius: 37px;
  margin-top: 20px;
  @media screen and (max-width: 850px) {
    display: none;
  }
`;

const ShareLink = ({ t, handleShowMap }) => {
  // eslint-disable-next-line max-len
  const iframeSrc = `${window.location.origin}${window.location.pathname}${window.location.search ? `${window.location.search}&embed=1` : '?embed=1'}${window.location.hash}`;
  return (
    <Wrapper>
      <ModalInputLink value={iframeSrc} onFocus={e => e.target.select()} readOnly />
      <SocialWrapper>
        <SocialLink color='#4267b2' onClick={() => socialShare('fb')}>
          <SocialIcon className='icon-facebook' />
        </SocialLink>
        <SocialLink color='#5996d9' onClick={() => socialShare('vk')}>
          <SocialIcon className='icon-vkontakte' />
        </SocialLink>
        <SocialLink color='#f58002' onClick={() => socialShare('odn')}>
          <SocialIcon className='icon-odnoklassniki' />
        </SocialLink>
      </SocialWrapper>
      <HtmlSwitcher onClick={() => handleShowMap(true)}>{t('htmlswitcher')}</HtmlSwitcher>
    </Wrapper>
  );
};

ShareLink.propTypes = {
  t: PropTypes.func.isRequired,
  handleShowMap: PropTypes.func.isRequired,
};

export default ShareLink;
