import React, {
  useState, useEffect, useContext, useRef 
} from 'react';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import styled from 'styled-components';
import Cookies from 'universal-cookie';
import { MapCtx } from '@/src/contexts';
// import objects3DRender from '@/src/3DObjects';

const TipWrapper = styled.div`
  position: absolute;
  right: 0;
  bottom: 0;
  padding: 20px 51px 20px 20px;
  min-width: 320px;
  max-width: 100%;
  white-space: nowrap;
  margin-right: 70px;
  background-color: #ffffff;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.1), 0 3px 10px 0 rgba(0, 0, 0, 0.07);
  border-radius: 10px;
  &:after {
    content: '';
    position: absolute;
    right: -8px;
    bottom: 13px;
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 15.5px 0 15.5px 14.7px;
    border-color: transparent transparent transparent #ffffff;
  }
`;

const Wrapper = styled.div`
  position: absolute;
  right: 15px;
  bottom: 83px;
  &:not(.is-embed) {
    ${({ theme }) => theme.onSM(`
      display: none;
    `)}
    ${TipWrapper} {
      ${({ theme }) => theme.onSM(`
        display: none;
      `)}
    }
  }
  @media screen and (max-height: 310px) {
    display: none;
  }
  @media screen and (max-width: 300px) {
    display: none;
  }
`;

const Container = styled.div``;

const CloseTipButton = styled.button`
  padding: 0;
  margin: 0;
  outline: none;
  position: absolute;
  right: 20px;
  top: 20px;
`;

const CloseIcon = styled.span`
  font-size: 1.9rem;
  color: #737373;
`;

const TipTitle = styled.p`
  color: #000000;
  font-size: 1.6rem;
  font-weight: 600;
  line-height: 22px;
  margin: 0 0 4px;
  white-space: normal;
`;

const TipDescription = styled.div`
  color: #000000;
  font-size: 1.4rem;
  line-height: 20px;
  white-space: normal;
`;

const Rotor = styled.span`
  display: block;
  height: 56px;
  width: 56px;
  padding: 10px;
  border-radius: 100px;
  background-image: url('/static/images/compass.svg');
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.1), 0 3px 10px 0 rgba(0, 0, 0, 0.07);
  &:hover {
    cursor: grab;
  }
  &.is-grabbing {
    cursor: grabbing;
  }
`;

const Switcher = styled.button`
  border-radius: 100px;
  display: block;
  position: absolute;
  padding: 0;
  height: 20px;
  line-height: 0;
  top: 19px;
  left: 19px;
  z-index: 200;
  ${({ theme }) => theme.onSM(`
    top:0;
    left:0;
    width:100%;
    height:100%;
  `)}
`;

const ModeIcon = styled.span`
  line-height: 0;
  font-size: 1.8rem;
  color: rgba(0, 0, 0, 0.54);
`;

const R2D = 180 / Math.PI;

const Compass = (props) => {
  const { t, MapGL, urlParams } = props;
  const { embed, print, viewType } = urlParams;
  const compassRef = useRef(null);
  const modeIconRef = useRef(null);
  const { objects3D } = useContext(MapCtx);
  const cookies = new Cookies(typeof document !== 'undefined' && document.cookie);
  const [isGrabbing, toggleGrabbing] = useState(false);
  const [isTipOpened, toggleTip] = useState(false);
  const [pitchValue, changePitch] = useState(MapGL.getPitch());
  const [bearingValue, setBearingValue] = useState(0);

  const onMouseDown = () => {
    const bearing = MapGL.getBearing();
    setBearingValue(bearing);
    toggleGrabbing(true);
  };

  const onMouseUp = () => {
    if (!MapGL.getBearing) return;
    const { current } = compassRef;
    const bearing = MapGL.getBearing();
    if (isGrabbing && bearingValue === bearing) {
      current.style.transform = `rotate(${0}deg)`;
      MapGL.setBearing(0);
    }
    toggleGrabbing(false);
  };

  const closeTip = () => {
    cookies.set('map.md.modeTip', '1', { path: '/' });
    toggleTip(false);
  };

  const compassMove = (e) => {
    if (!isGrabbing) return;
    const { current } = compassRef;
    const {
      top, left, height, width 
    } = current.getBoundingClientRect();
    const center = {
      x: left + width / 2,
      y: top + height / 2,
    };
    const x = e.clientX - center.x;
    const y = e.clientY - center.y;
    const degree = R2D * Math.atan2(y, x) + 90;
    current.style.transform = `rotate(${degree}deg)`;
    MapGL.setBearing(degree * -1);
  };

  const setPitch = () => {
    if (MapGL.getPitch() > 0) {
      MapGL.flyTo({ pitch: 0 });
      changePitch(0);
      return;
    }
    MapGL.flyTo({ pitch: 60 });
    changePitch(60);
  };

  const toggle3DRender = (pitch) => {
    if (pitch > 0 && viewType !== 'sateliteStyle' && objects3D && objects3D.length) {
      // objects3DRender(MapGL, objects3D, i18n.language);
      objects3D.forEach((object) => {
        if (MapGL.getLayer(object.title)) {
          MapGL.setLayoutProperty(object.title, 'visibility', 'visible');
        }
      });
    }
  };

  useEffect(() => {
    MapGL.once('pitchend', (e) => {
      const pitch = e.target.getPitch();
      toggle3DRender(pitch);
      changePitch(pitch);
    });
  }, [viewType, MapGL]);

  useEffect(() => {
    const tipShow = !cookies.get('map.md.modeTip');
    toggleTip(tipShow);
    const { current } = compassRef;
    // radiance to degrees
    MapGL.on('rotate', () => {
      const bearing = MapGL.getBearing();
      current.style.transform = `rotate(${bearing * -1}deg)`;
    });
    // setCompassPosition();
  }, [MapGL]);

  useEffect(() => {
    const { current } = compassRef;
    current.addEventListener('mousedown', onMouseDown);
    current.addEventListener('mouseup', onMouseUp);
    current.addEventListener('mouseleave', onMouseUp);
    current.addEventListener('mousemove', compassMove);
    current.addEventListener('touchstart', compassMove);
    // current.addEventListener('mouseout', removeListener);
    return () => {
      current.removeEventListener('mousedown', onMouseDown);
      current.removeEventListener('mouseup', onMouseUp);
      current.removeEventListener('mouseleave', onMouseUp);
      current.removeEventListener('mousemove', compassMove);
      current.removeEventListener('touchstart', compassMove);
      // current.removeEventListener('mouseout', removeListener);
    };
  });
  const currentMode = pitchValue ? '2D' : '3D';
  const alterMode = !pitchValue ? '2D' : '3D';
  return (
    <Wrapper className={(embed || print) && 'is-embed'}>
      {(!embed && !print && isTipOpened) && (
        <TipWrapper>
          <CloseTipButton onClick={closeTip}>
            <CloseIcon className='icon-close' />
          </CloseTipButton>
          <TipTitle>{t('Map is in D mode', { mode: alterMode })}</TipTitle>
          <TipDescription>{t('Press the compass to go into D mode', { mode: currentMode })}</TipDescription>
        </TipWrapper>
      )}
      <Container>
        <Switcher onClick={setPitch}>
          <ModeIcon ref={modeIconRef} className={`icon-${currentMode}`} />
        </Switcher>
        <Rotor className={isGrabbing && 'is-grabbing'} ref={compassRef} />
      </Container>
    </Wrapper>
  );
};

Compass.propTypes = {
  t: PropTypes.func.isRequired,
  urlParams: PropTypes.object.isRequired,
  // i18n: PropTypes.object.isRequired,
  MapGL: PropTypes.object.isRequired,
};

export default withTranslation()(Compass);
