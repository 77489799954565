import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import styled, { withTheme } from 'styled-components';
import { checkUserLogged } from '@/src/helpers';
import { MapToolsCtx } from '@/src/contexts';

const Button = styled.button`
  padding: 9px 8px;
  display: inline-block;
  color: rgba(0, 0, 0, 0.54);
  &:hover,
  &.active {
    color: ${({ theme }) => theme.colors.accent};
  }
`;

const ToolIcon = styled.span`
  font-size: 1.8rem;
  line-height: 0;
`;

const MarkerTool = (props) => {
  const { mapMode, t } = props;
  return (
    <MapToolsCtx.Consumer>
      {({ mapTools }) => (
        <Button
          data-rh={t('Marker')}
          className={`${mapMode === 'markers' && 'active'}`}
          onClick={() => {
            if (!checkUserLogged()) return;
            mapTools.modeSwitcher('markers');
          }}
        >
          <ToolIcon className='icon-place' />
        </Button>
      )}
    </MapToolsCtx.Consumer>
  );
};

MarkerTool.propTypes = {
  mapMode: PropTypes.string.isRequired,
  t: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({
  mapMode: state.map.mode,
});

export default connect(mapStateToProps)(withTranslation()(withTheme(MarkerTool)));
