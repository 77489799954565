import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import Toolbar from '@/src/components/Toolbar';
import ZoomControl from '@/src/components/ZoomControl';
import Compass from '@/src/components/Compass';
import PrintControl from '@/src/components/Toolbar/components/PrintTool/PrintControl';
import MyLocation from '@/src/components/Toolbar/components/MyLocation';
import LayerSwitcher from '@/src/components/LayerSwitcher';
import LevelsButtons from '@/src/components/LevelsButtons';

const MyLocationWrapper = styled.div`
  position: absolute;
  top: 66px;
  right: 8px;
  background-color: #ffffff;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.1), 0 3px 10px 0 rgba(0, 0, 0, 0.07);
  border-radius: 100px;
  width: 36px;
  height: 36px;
  &:not(.is-embed) {
    display: none;
  }
  & > * {
    display: inline-block;
    vertical-align: middle;
  }
  &.is-embed {
    top: 16px;
    right: 14px;
  }
  ${({ theme }) => theme.onSM(`
    display: block !important;
    top: 72px;
  `)}
  @media screen and (max-height: 230px) {
    display: none;
  }
`;

const MapControls = (props) => {
  const { urlParams, MapGL } = props;
  const { embed, print } = urlParams;
  return (
    <Fragment>
      {!embed && !print && <Toolbar urlParams={urlParams} MapGL={MapGL} />}
      {print && <PrintControl />}
      <ZoomControl MapGL={MapGL} urlParams={urlParams} />
      <MyLocationWrapper className={(embed || print) && 'is-embed'}>
        <MyLocation noHint />
      </MyLocationWrapper>
      <LevelsButtons MapGL={MapGL} />
      <Compass MapGL={MapGL} urlParams={urlParams} />
      <LayerSwitcher urlParams={urlParams} MapGL={MapGL} />
    </Fragment>
  );
};

MapControls.propTypes = {
  urlParams: PropTypes.object.isRequired,
  MapGL: PropTypes.object.isRequired,
};

export default MapControls;
