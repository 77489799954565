import React from 'react';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import styled from 'styled-components';
import { addQueryStringToUrl } from '@/src/helpers';
// import { Link } from '@/routes';

const Button = styled.button`
  padding: 9px;
  display: inline-block;
  color: rgba(0, 0, 0, 0.54);
  &:hover,
  &.active {
    color: ${({ theme }) => theme.colors.accent};
  }
`;

const SUFFIX_YEARS = ['1880', '1940', '1960', '2020'];

const YearTool = (props) => {
  const { t, year, urlParams } = props;
  const onClick = y => () => {
    addQueryStringToUrl({ year: y }, false, true);
  };

  const getSuffix = (yr) => {
    if (SUFFIX_YEARS.indexOf(yr) > -1) {
      return `${yr}-e`;
    }
    return yr;
  };

  return (
    <Button 
      onClick={onClick(year)}
      className={(!urlParams.year && year === '1940') || urlParams.year === year ? 'active' : ''}
      data-rh={t('Год')}
    >
      {getSuffix(year)}
    </Button>
    
  );
};

YearTool.propTypes = {
  t: PropTypes.func.isRequired,
  year: PropTypes.string.isRequired,
  urlParams: PropTypes.object.isRequired
};

export default withTranslation()(YearTool);
