import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

const Link = styled.a`
  text-align: center;
  position: absolute;
  top: 19px;
  left: 0;
  width: 108px;
  right: 0;
  margin: auto;
  z-index: 999;
  ${({ theme, embed }) => theme.onMD(`
    display: ${embed ? 'block' : 'none'};
  `)}
  ${({ print }) => (print
    ? `
      left: 6px;
      top: 18px;
      margin: unset`
    : '')}
`;

const LogoImage = styled.img`
  ${({ print }) => print && 'width: 76px'}
  ${({ embed }) => embed && `
    @media screen and (max-width: 300px) {
      width: 50px;
    }
  `}
`;

const Logo = (props) => {
  const { urlParams } = props;
  const { 
    print, embed, viewType, pageName
  } = urlParams;
  if (pageName && pageName.match(/api/)) return null;
  // let logoType = viewType === 'sateliteStyle' ? '-sputnik' : '';
  // if (print) logoType = '';
  return null;
  // return (
  //   <Link href={!embed ? '/' : 'https://old.map.md'} target={embed && '_blank'} print={print} embed={embed}>
  //     <LogoImage
  //       src={`/static/images/map-logo${logoType}.svg`}
  //       print={print}
  //       embed={embed}
  //     />
  //   </Link>
  // );
};

Logo.propTypes = {
  urlParams: PropTypes.object.isRequired,
};
export default Logo;
