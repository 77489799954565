/* eslint-disable max-len */
import { css } from 'styled-components';

const colors = {
  accent: '#ff7201',
  accentOpacity: opacity => `rgba(255,130,1,${opacity})`,
};

const onXS = styles => css`
  @media screen and (min-device-width: 320px) and (max-device-width: 480px) and (-webkit-min-device-pixel-ratio: 2) {
    ${styles}
  }
`;

// iphones 6/7/8 and X
const onSM = styles => css`
  @media only screen
  and (min-device-width : 375px) 
  and (max-device-width : 850px) {
    ${styles}
  }
  @media only screen 
  and (max-device-width : 375px) {
    ${styles}
  }
  @media only screen
  and (min-device-width : 375px) 
  and (max-device-width : 812px)
  and (-webkit-device-pixel-ratio : 3) {
    ${styles}
  }
`;

const fromSM = styles => css`
  @media screen and (min-width: 736px) {
    ${styles}
  }
`;

const onMD = styles => css`
  @media screen and (max-width: 991px) {
    ${styles}
  }
`;

const onLG = styles => css`
  @media screen and (max-width: 1100px) {
    ${styles}
  }
`;

const onContainer = styles => css`
  @media screen and (max-width: 1252px) {
    ${styles}
  }
`;

const container = {
  width: 1237,
};
const fonts = {
  main: 'Proxima Nova',
};

export default {
  colors,
  fonts,
  container,
  onXS,
  onSM,
  fromSM,
  onMD,
  onLG,
  onContainer,
};
