import React from 'react';
import styled from 'styled-components';
import theme from '@/src/theme';

const Wrapper = styled.div``;

const MarkerIcon = styled.span`
  line-height:0;
  color: ${theme.colors.accent};
  font-size: 3.0rem;
`;

const MapMarker = () => (
  <Wrapper>
    <MarkerIcon className='icon-place' />
  </Wrapper>
);

export default MapMarker;
