import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';

const Button = styled.button`
  padding: 9px 8px;
  display: inline-block;
  color: rgba(0, 0, 0, 0.54);
  &:hover {
    color: ${({ theme }) => theme.colors.accent};
  }
`;

const ToolIcon = styled.span`
  font-size: 1.8rem;
  line-height: 0;
`;

const printMap = () => {
  // eslint-disable-next-line max-len
  const href = `${window.location.origin}${window.location.pathname}${window.location.search ? `${window.location.search}&print=1` : '?print=1'}${window.location.hash}`;
  const params = 'toolbar=0,status=0,width=740,height=780';
  window.open(href, 'Print', params);
};

const PrintTool = (props) => {
  const { t } = props;
  return (
    <Button data-rh={t('Print')} onClick={() => printMap()}>
      <ToolIcon className='icon-print' />
    </Button>
  );
};

PrintTool.propTypes = {
  t: PropTypes.func.isRequired,
};

export default withTranslation()(PrintTool);
