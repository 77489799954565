import React, { useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { connect } from 'react-redux';
import classNames from 'classnames';
import initGtmParts from '@/src/components/GTM/tool';
import * as CONST from '@/constants';

const PointMap = styled.div`
  width: 100%;
  height: 100%;
  min-height: 100%;
  &:not(.is-embed) {
    @media screen and (max-width: 850px) {
      height: calc(100vh - 50px);
      min-height: calc(100vh - 50px);
    }
  }
  & .mapboxgl-canvas-container {
    height: 100%;
  }
`;

let mapRef = null;
let map = null;

const setAuthHeader = (url) => {
  if (url.indexOf('google') > -1) {
    return { url };
  }
  return {
    url,
    headers: {
      Authorization: CONST.AUTHORIZATION,
    },
  };
};

const Map = (props) => {
  const mapRefRef = useRef(null);
  const { mapStyle } = props;
  const src = mapStyle && mapStyle.sources.openmaptiles.url;
  const apiSrcSplit = src.split('/api/');
  const apiSrc = apiSrcSplit && apiSrcSplit[1];

  const initMap = () => {
    const { urlParams } = props;
    // eslint-disable-next-line global-require
    const mapboxgl = require('mapbox-gl');
    initGtmParts(CONST.GTM_ID);
    if (!mapboxgl.supported()) {
      window.dataLayer.push({
        event: 'checkWebGLSupport',
        userAgent: navigator.userAgent,
        isWebGLSupported: false,
      });
      // eslint-disable-next-line no-alert
      window.alert('Your browser does not support WebGL');
      return;
    }
    if (typeof window !== 'undefined' && window.dataLayer) {
      window.dataLayer.push({
        event: 'checkWebGLSupport',
        userAgent: navigator.userAgent,
        isWebGLSupported: true,
      });
    }
    const { z, x, y } = CONST.MAP_DEFAULTS;
    let southWest = new mapboxgl.LngLat(24.0, 45.0);
    let northEast = new mapboxgl.LngLat(32.0, 49.0);
    let minZoom = 7;
    if (urlParams.year === '1940' || !urlParams.year) {
      minZoom = 12;
      southWest = new mapboxgl.LngLat(28.664469614752136, 46.98426042791198);
      northEast = new mapboxgl.LngLat(29.04073990568517, 47.055872058581286);
    }
    if (urlParams.year === '1960') {
      minZoom = 11;
      northEast = new mapboxgl.LngLat(29.16347305369095, 47.10952320331356);
      southWest = new mapboxgl.LngLat(28.50429336619166, 46.92648334254238);
      // northEast = new mapboxgl.LngLat(29.089294776739507, 47.102241123538676);
      // southWest = new mapboxgl.LngLat(28.74219327710506, 46.94084837066549);

      // northEast = new mapboxgl.LngLat(29.20877544131727, 47.092450851514656);
      // southWest = new mapboxgl.LngLat(28.52526474431241, 46.93905416130178);
    }
    const bounds = new mapboxgl.LngLatBounds(southWest, northEast);
    map = new mapboxgl.Map({
      container: mapRef,
      style: mapStyle,
      transformRequest: setAuthHeader,
      center: [y, x],
      maxBounds: bounds,
      renderWorldCopies: false,
      hash: true,
      minZoom,
      pitch: 0,
      bearing: 0,
      zoom: z,
      attributionControl: false,
      maxZoom: 20,
    });
    map.touchZoomRotate.enable();
    props.addListener(map);
    mapRefRef.current = map;
    window.mapInstance = map;
    return map;
  };

  const toggleMapCursor = () => {
    if (!map) return;
    const { mapMode } = props;
    const mapCanvas = map.getCanvas();
    switch (mapMode) {
      case 'markers':
        mapCanvas.style.cursor = 'crosshair';
        break;

      case 'ruler':
        mapCanvas.style.cursor = 'crosshair';
        break;

      default:
        mapCanvas.style.cursor = '';
    }
  };
  useEffect(() => {
    const initialMap = initMap();
    return () => {
      if (!props.removeListeners) return;
      props.removeListeners(initialMap);
    };
  }, []);

  useEffect(() => {
    toggleMapCursor();
  });

  useEffect(() => {
    const MapGL = mapRefRef.current;
    if (MapGL.loaded()) {
      MapGL.setStyle(mapStyle, { diff: false });
    }
  }, [apiSrc]);

  const { urlParams: { print, embed } } = props;

  return (
    <PointMap
      className={classNames({
        'is-embed': print || embed,
      })}
      ref={(ref) => {
        mapRef = ref;
      }}
    />
  );
};

Map.propTypes = {
  mapStyle: PropTypes.object.isRequired,
  addListener: PropTypes.func.isRequired,
  removeListeners: PropTypes.func.isRequired,
  mapMode: PropTypes.string.isRequired,
  urlParams: PropTypes.object.isRequired
};

const mapStateToProps = state => ({
  mapMode: state.map.mode,
});

export default connect(mapStateToProps)(Map);
