import React from 'react';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import styled from 'styled-components';
import { MapToolsCtx } from '@/src/contexts';

const Button = styled.button`
  padding: 9px;
  display: inline-block;
  color: rgba(0, 0, 0, 0.54);
  &:hover,
  &.active {
    color: ${({ theme }) => theme.colors.accent};
  }
`;

const ToolIcon = styled.span`
  font-size: 1.8rem;
  line-height: 0;
`;

const PanTool = (props) => {
  const { t } = props;
  return (
    <MapToolsCtx.Consumer>
      {({ mapTools }) => (
        <Button onClick={() => mapTools.modeSwitcher('')} data-rh={t('Cursor')}>
          <ToolIcon className='icon-pan_tool' />
        </Button>
      )}
    </MapToolsCtx.Consumer>
  );
};

PanTool.propTypes = {
  t: PropTypes.func.isRequired,
};

export default withTranslation()(PanTool);
