import React, { useState } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import Modal from 'react-modal';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import ShareLink from './ShareLink';
import IframeLink from './IframeLink';

const ModalWrapper = styled.div`
  position: relative;
  background: #fff;
  display: inline-block;
  text-align: left;
  width: auto;
  overflow: auto;
  box-sizing: border-box;
  border-radius: 5px;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.14), 0 3px 10px 0 rgba(0, 0, 0, 0.07);
  font-size: 62.5%;
`;

const ModalContent = styled.div`
  max-width: ${({ screenWidth }) => screenWidth}px;
  overflow: hidden;
`;

const ModalCloseBtn = styled.button`
  position: absolute;
  top: 10px;
  right: 10px;
  line-height: 30px;
  font-size: 1.9rem;
  color: #737373;
  padding: 0;
  background-color: transparent;
  text-align: center;
  outline: none;
  z-index: 31;
`;

const ModalTitle = styled.h4`
  padding: 0 30px;
  width: 380px;
  max-width: 100%;
  height: 19px;
  margin-bottom: 10px;
  opacity: 0.87;
  font-size: 1.6rem;
  line-height: 19px;
`;

const ModalTheme = {
  overlay: {
    zIndex: '5000',
    backgroundColor: 'rgba(245,245,245,0.97)',
    textAlign: 'center',
    overflow: 'auto',
    paddingBottom: '70px',
  },
  content: {
    position: 'unset',
    display: 'inline-block',
    color: 'none',
    left: '0',
    right: '0',
    top: 100,
    overflow: 'visible',
    bottom: '0',
    margin: '100px auto 0',
    background: 'none',
    backgroundColor: 'none',
    padding: 0,
    border: 'none',
    width: 'auto',
    WebkitOverflowScrolling: 'touch',
  },
};

const ModalComponent = (props) => {
  const [showMap, setshowMap] = useState(false);
  const {
    isOpened, t, MapGL, mapStyle, modalHandler 
  } = props;

  const handleShowMap = (show) => {
    // const { modalHandler } = this.props;
    setshowMap(show);
    // modalHandler(showMap);
  };

  return (
    <Modal
      onRequestClose={() => modalHandler(!isOpened)}
      shouldCloseOnOverlayClick
      isOpen={isOpened}
      style={ModalTheme}
      contentLabel='ShareModal'
    >
      <ModalWrapper>
        <ModalContent screenWidth={typeof window !== 'undefined' && window.screen.width}>
          <ModalCloseBtn className='icon-close' onClick={() => modalHandler(!isOpened)} />
          <ModalTitle>{!showMap ? t('iframesettings') : t('maplink')}</ModalTitle>
          <ShareLink t={t} handleShowMap={handleShowMap} />
          <Modal
            onRequestClose={() => handleShowMap(!isOpened)}
            shouldCloseOnOverlayClick
            isOpen={showMap}
            style={ModalTheme}
            contentLabel='ShareModal2'
          >
            <ModalWrapper>
              <ModalContent screenWidth={typeof window !== 'undefined' && window.screen.width}>
                <ModalCloseBtn className='icon-close' onClick={() => handleShowMap(!isOpened)} />
                <ModalTitle>{!showMap ? t('iframesettings') : t('maplink')}</ModalTitle>
                <IframeLink t={t} MapGL={MapGL} mapStyle={mapStyle} />
              </ModalContent>
            </ModalWrapper>
          </Modal>
        </ModalContent>
      </ModalWrapper>
    </Modal>
  );
};

ModalComponent.propTypes = {
  t: PropTypes.func.isRequired,
  isOpened: PropTypes.bool.isRequired,
  modalHandler: PropTypes.func.isRequired,
  MapGL: PropTypes.object.isRequired,
  mapStyle: PropTypes.object.isRequired,
};

const mapStateToProps = state => ({
  mapStyle: state.tileStyles.mapStyles,
  isOpened: state.map.isShareModalOpened,
});

const ShareModal = connect(mapStateToProps)(ModalComponent);

export default withTranslation()(ShareModal);
