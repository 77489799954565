import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import styled from 'styled-components';
import { MapToolsCtx } from '@/src/contexts';

const Button = styled.button`
  padding: 9px 8px;
  display: inline-block;
  margin: 0;
  color: rgba(0, 0, 0, 0.54);
  ${({ theme, noHint }) => {
    if (noHint) {
      return `
        &:hover,
        &.active {
          color: ${theme.colors.accent};
        }`;
    }
  }}
  
`;

const ToolIcon = styled.span`
  font-size: 1.8rem;
  line-height: 0;
`;

const MyLocation = (props) => {
  const { t, noHint } = props;
  const { mapTools } = useContext(MapToolsCtx);
  const getUserLoc = () => mapTools.getUserLocation();

  return (
    <Button 
      data-rh={noHint ? null : t('My location')}
      onClick={getUserLoc}
    >
      <ToolIcon className='icon-near_me' />
    </Button>
  );
};

MyLocation.defaultProps = {
  noHint: false
};

MyLocation.propTypes = {
  t: PropTypes.func.isRequired,
  noHint: PropTypes.bool,
};

export default withTranslation()(MyLocation);
