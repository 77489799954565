import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { withTranslation } from 'react-i18next';
import FBLikeWidget from '@/src/components/Widgets/FBLikeWidget';

const Wrapper = styled.div`
  overflow-y: scroll;
  overflow-x: hidden;
  max-height: calc(100% - 200px);
  padding-bottom: 20px;
  right: 15px;
  margin-top: 50px;
  position: fixed !important;
  // height: 575px;
  width: 263px;
  background-color: #fff;
  border-radius: 5px;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.1), 0 3px 10px 0 rgba(0, 0, 0, 0.07);
`;

const InfoBlock = styled.div`
  box-sizing: border-box;
  padding: 0 0 18px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
`;

const Title = styled.div`
  font-size: 1.6rem;
  padding: 20px 0 18px 20px;
  color: rgba(0, 0, 0, 0.4);
`;

const InfoLine = styled.a`
  height: 32px;
  font-size: 1.3rem;
  line-height: 32px;
  border-left: 2px solid #fff;
  display: block;
  &:hover {
    border-left: 2px solid #ff7201;
    background-color: rgba(0, 0, 0, 0.02);
  }
`;
const LineIcon = styled.span`
  vertical-align: middle;
  margin: 0 20px;
  font-size: 1.8rem;
  color: rgba(0, 0, 0, 0.54);
`;
const FbWrapper = styled.div`
  padding: 20px 13px 0;
  white-space: nowrap;
  text-align: center;
  & > * {
    margin: 0 5px;
    width: 110px;
    height: 34px !important;
    display: inline-block;
    vertical-align: middle;
  }
`;

const FaceBookBtn = styled.a`
  display: inline-block;
  border-radius: 4px;
  background-color: #f5f5f5;
  text-align: center;
  line-height: 30px;
  &:hover {
    opacity: .9;
  }
`;

const StoreWrapper = styled.div`
  margin-top:11px;
  padding: 0 13px;
  white-space: nowrap;
`;

const StoreLink = styled.a`
  display: inline-block;
  text-align:center;
  width:110px;
  margin: 0 5px;
  height:34px;
  &:hover {
    opacity: .9;
  }
  &.android {
    background: url('/static/images/google.svg') no-repeat center;
  }
  &.ios {
    background: url('/static/images/appstore.svg') no-repeat center;
  }
`;

const InfoToolPopup = (props) => {
  const { t } = props;
  return (
    <Wrapper>
      <InfoBlock>
        <Title>{t('info')}</Title>
        <InfoLine href='/info/about' target='_blank'>
          <LineIcon className='icon-description' />
          {t('proect')}
        </InfoLine>
        {/* <InfoLine>
          <LineIcon className='icon-report_problem' />
          {t('rules')}
        </InfoLine> */}
        <InfoLine href='/blog' target='_blank'>
          <LineIcon className='icon-write' />
          {t('blog')}
        </InfoLine>
        <InfoLine href='/api' target='_blank'>
          <LineIcon className='icon-code_rounded' />
          {t('API')}
        </InfoLine>
      </InfoBlock>
      <InfoBlock>
        <Title>{t('support')}</Title>
        <InfoLine href='/info/help' target='_blank'>
          <LineIcon className='icon-directions_lifebuoy' />
          {t('help')}
        </InfoLine>
        <InfoLine href='/info/confidentialitate' target='_blank'>
          <LineIcon className='icon-cookie' />
          {t('cookies')}
        </InfoLine>
        <InfoLine href='mailto:info@map.md '>
          <LineIcon className='icon-email' />
          info@map.md
        </InfoLine>
      </InfoBlock>
      <InfoBlock>
        <Title>{t('publicitate')}</Title>
        <InfoLine href='https://numbers.md' target='_blank'>
          <LineIcon className='icon-image' />
          {t('banners')}
        </InfoLine>
        <InfoLine href='tel:+37322888001'>
          <LineIcon className='icon-call' />
          {t('telephone')}
          : (+373) 22 888 001
        </InfoLine>
        <InfoLine href='mailto:info@numbers.md'>
          <LineIcon className='icon-email' />
          info@numbers.md
        </InfoLine>
      </InfoBlock>
      <FbWrapper>
        <FaceBookBtn target='_blank' href='https://www.facebook.com/wwwmapmd/'>
          <LineIcon className='icon-facebook' />
        </FaceBookBtn>
        <FBLikeWidget />
      </FbWrapper>
      <StoreWrapper>
        <StoreLink
          className='android'
          href='https://play.google.com/store/apps/details?id=md.point.map'
          target='_blank'
        />
        <StoreLink
          className='ios'
          href='https://itunes.apple.com/md/app/point-map-map-of-moldova/id407750567?mt=8'
          target='_blank'
        />
      </StoreWrapper>
    </Wrapper>
  );
};

InfoToolPopup.propTypes = {
  t: PropTypes.func.isRequired,
};

export default withTranslation()(InfoToolPopup);
