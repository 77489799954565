import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

const Label = styled.div`
  position: relative;
  display: inline-block;
  height: 18px;
  line-height: 18px;
  background-color: #fff;
  padding: 0 7px 0 23px;
  top: -3px;
  left: -5px;
  border-radius: 10px;
`;

const IconEnd = styled.img`
  position: absolute;
  top: -19px;
  z-index: 999999;
`;

const Distance = styled.span`
  white-space: no-wrap;
`;

const RemoveDistace = styled.a`
  margin-left: 5px;
  width: 10px;
  height: 10px;
  font-size: 1.7rem;
  line-height: 5px;
  color: #444;
  text-decoration: none;
`;

const StartIcon = () => (
  <div className='ruler-start'>
    <img alt='Ruller flag' src='/static/images/flag.svg' />
  </div>
);

const EndIcon = ({ removePath }) => (
  <div className='mapboxgl-marker-ruler-end leaflet-path-marker-end-divicon'>
    <IconEnd src='/static/images/flag.svg' />
    <Label>
      <Distance id='distance-measure' />
      <RemoveDistace onClick={e => removePath(e)}>×</RemoveDistace>
    </Label>
  </div>
);

EndIcon.propTypes = {
  removePath: PropTypes.func.isRequired,
};

export { StartIcon, EndIcon };
