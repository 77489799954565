import gtmParts from 'react-google-tag-manager';

const initGtmParts = (gtmId) => {
  const gtm = gtmParts({
    id: gtmId,
    additionalEvents: {},
    dataLayerName: 'dataLayer',
  });

  if (!window.dataLayer) {
    eval(gtm.scriptAsHTML().replace(/<\/?script>/g, '')); // eslint-disable-line no-eval
  }
};

export default initGtmParts;
