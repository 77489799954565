import React, { useState, useEffect } from 'react';
// import { withRouter } from 'next/router';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import theme from '@/src/theme';

const Wrapper = styled.div`
  min-width: 298px;
  max-width: 100%;
  padding: 16px;
  border-radius: 10px;
  position: relative;
`;

const Title = styled.p`
  height: 16px;
  opacity: 0.87;
  color: #1A1A1A;
  font-family: "SF Pro Display";
  font-size: 1.4rem;
  font-weight: 600;
  line-height: 16px;
  margin: 0 0 9px;
`;
const Subtitle = styled.p`
  height: 15px;
  opacity: 0.5;
  color: #000000;
  font-family: "SF Pro Display";
  font-size: 1.3rem;
  line-height: 15px;
  margin:0;
`;

const RoutingButton = styled.button`
  border: none;
  padding: 0;
  outline: none;
  position: absolute;
  right: 20px;
  top:0;
  bottom: 0;
  margin: auto;
  width:32px;
  height:32px;
`;

const RoutingButtonIcon = styled.span`
  font-size: 3.2rem;
  color: ${theme.colors.accent};
`;

const BuildingMarker = (props) => {
  // let linkInputHref = null;
  const [distance, setDistance] = useState({ userLocation: null });
  const {
    deleteMarker,
    markerPosition,
    routingTools,
    getLocation,
    streetName,
    cityName
  } = props;

  const getDistance = async () => {
    const position = await getLocation();
    if (position && !position.coords) return;
    const { coords } = position;
    setDistance({ userLocation: coords });
  };

  const routingHandler = () => {
    const { latitude, longitude } = distance.userLocation || {};
    const waypointCoords = {};
    if (latitude && longitude) {
      waypointCoords.a = [longitude, latitude];
      waypointCoords.b = [markerPosition.lng, markerPosition.lat];
    } else {
      waypointCoords.b = { lon: markerPosition.lng, lat: markerPosition.lat };
    }
    routingTools.setWaypoint(waypointCoords);
  };

  useEffect(() => {
    getDistance();
  }, []);

  return (
    <Wrapper>
      <Title>
        {streetName}
      </Title>
      <Subtitle>
        {cityName}
      </Subtitle>
      <RoutingButton 
        onClick={() => {
          routingHandler();
          // routingTools.setWaypoint({ a: null, b: markerPosition });
          deleteMarker();
        }}
      >
        <RoutingButtonIcon className='icon-directions' />
      </RoutingButton>
    </Wrapper>
  );
};

BuildingMarker.defaultProps = {
  streetName: 'No name',
  cityName: 'No name'
};

BuildingMarker.propTypes = {
  deleteMarker: PropTypes.func.isRequired,
  getLocation: PropTypes.func.isRequired,
  markerPosition: PropTypes.object.isRequired,
  routingTools: PropTypes.object.isRequired,
  streetName: PropTypes.string,
  cityName: PropTypes.string,
};

export default BuildingMarker;
