import React, { Fragment, useState } from 'react';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import styled from 'styled-components';
import ReactHintFactory from 'react-hint';
import InfoToolPopup from './InfoToolPopup';

const Button = styled.button`
  padding: 9px 8px;
  display: inline-block;
  color: rgba(0, 0, 0, 0.54);
  &:hover,
  &.active {
    color: ${({ theme }) => theme.colors.accent};
  }
`;

const ToolIcon = styled.span`
  font-size: 1.8rem;
  line-height: 0;
`;

const ToolTip = styled.div`
  position:absolute;
  right: -10px;
  background: #fff;
  padding: 5px 12px;
  height: 24px;
  border-radius: 14px;
  background-color: #ffffff;
  box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.18);
  color: #000000;
  font-family: SF Pro Display;
  font-size: 1.2rem;
  font-weight: 500;
  text-align: center;
`;

const InfoTool = ({ t }) => {
  const [show, ShowHandler] = useState(false);
  const ReactHint = ReactHintFactory(React);
  return (
    <Fragment>
      <ReactHint
        events
        position='bottom'
        attribute='data-custom-tool-tip'
        onRenderContent={() => <ToolTip>{t('Information')}</ToolTip>}
      />
      <Button
        data-custom-tool-tip
        className={show ? 'active' : ''}
        onClick={() => ShowHandler(!show)}
      >
        <ToolIcon className='icon-info' />
      </Button>
      {show && <InfoToolPopup />}
    </Fragment>
  );
};

InfoTool.propTypes = {
  t: PropTypes.func.isRequired,
};

export default withTranslation()(InfoTool);
