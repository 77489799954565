import React from 'react';
import styled from 'styled-components';
import { withTranslation } from 'react-i18next';
import PropTypes from 'prop-types';

const Wrap = styled.div`
  position: fixed;
  top: 0;
  z-index: 200;
  width: 100%;
  background: #fff;
  height: 66px;
  padding: 16px;
  box-sizing: border-box;
  display: flex;
  justify-content: flex-end;
  box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.05), 0 1px 2px 0 rgba(94, 94, 94, 0.21);
  @media print {
    display: none !important;
  }
`;

const PrintBtn = styled.button`
  border: none;
  background: #ff7201;
  border-radius: 17px;
  line-height: 34px;
  color: #fff;
  padding: 0 19px;
  margin-left: 12px;
  font-weight: 500;
  height: 34px;
  width: 130px;
  font-size: 1.4rem;
  letter-spacing: -0.43px;
  text-align: center;
  outline: 0;
  &:hover {
    background: rgba(255, 114, 1, 0.8);
  }
`;

const CancelBtn = styled.button`
  height: 34px;
  font-size: 1.4rem;
  letter-spacing: -0.43px;
  text-align: center;
  outline: 0;
  border: none;
  width: 100px;
  border-radius: 17px;
  background-color: #f7f7f7;
  color: rgba(0, 0, 0, 0.87);
  line-height: 16px;
  &:hover {
    background: #ececec;
  }
`;

const printCancel = () => {
  window.close();
};

const printMap = () => {
  window.print();
};

const PrintControl = ({ t }) => (
  <Wrap>
    <CancelBtn onClick={() => printCancel()}>{t('cancel')}</CancelBtn>
    <PrintBtn onClick={() => printMap()}>{t('print')}</PrintBtn>
  </Wrap>
);

PrintControl.propTypes = {
  t: PropTypes.func.isRequired,
};

export default withTranslation()(PrintControl);
