import React from 'react';
import styled from 'styled-components';
import FacebookProvider, { Like } from 'react-facebook';

const Wrapper = styled.div`
  line-height: 30px;  
  width: 104px !important;
  margin-left: 8px !important;
`;

const FBLikeWidget = () => (
  <Wrapper>
    <FacebookProvider appId='323170778372512' language='ru_RU'>
      <Like
        href='https://www.facebook.com/wwwmapmd/'
        colorScheme='dark'
        showFaces={false}
        layout='button'
        share={false}
        size='large'
      />
    </FacebookProvider>
  </Wrapper>
);

export default FBLikeWidget;
