import React, { useState, useEffect } from 'react';
// import { withRouter } from 'next/router';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import theme from '@/src/theme';
import { changeURLPage } from '@/src/helpers';
import { actionDeleteUserMarker, actionSaveUserMarker } from '@/src/redux/actions';

const Wrapper = styled.div`
  // min-width: 358px;
  max-width: 100%;
`;

const Container = styled.div`
  max-width: 100%;
`;

const Tooltip = styled.div`
  padding: 5px;
  position: absolute;
  z-index: 9999;
  cursor: default;
  background: #fff;
  padding: 5px 12px;
  height: 24px;
  border-radius: 14px;
  background-color: #ffffff;
  box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.18);
  color: #000000;
  font-size: 1.2rem;
  font-weight: 500;
  text-align: center;
  white-space: nowrap;
  top: -32px;
  left: 0;
  opacity: 0;
  transition: opacity 0.5s ease-in-out;
`;

const HiddenInput = styled.input`
  height: 0;
  outline: none;
  border: none;
  padding: 0;
  margin: 0;
  position: absolute;
`;

const Textarea = styled.textarea`
  padding: 10px 16px;
  width: 100%;
  border-radius: 4px;
  background-color: #f5f5f5;
  color: rgba(0, 0, 0, 0.54);
  font-size: 1.3rem;
  line-height: 15px;
  resize: none;
  border: none;
  outline: none;
`;

const Footer = styled.div`
  margin-top: 20px;
`;

const ShareButton = styled.button`
  height: 36px;
  width: 36px;
  padding: 0;
  border-radius: 100px;
  background-color: #f7f7f7;
  float: left;
  position: relative;
  &.active {
    & ${Tooltip} {
      opacity: 1;
    }
  }
`;

const ShareButtonIcon = styled.span`
  display: inline-block;
  padding: 0;
  color: rgba(0, 0, 0, 0.54);
  line-height: 0;
  font-size: 2.4rem;
`;

const DeleteButton = styled.button`
  height: 36px;
  width: 100px;
  text-transform: capitalize;
  border-radius: 100px;
  background-color: #f7f7f7;
  font-size: 1.3rem;
  float: right;
  color: rgba(0, 0, 0, 0.54);
  margin-right: 10px;
`;
const SaveButton = styled.button`
  padding: 10px 16px;
  line-height: 16px;
  min-width: 100px;
  text-transform: capitalize;
  border-radius: 100px;
  background-color: ${theme.colors.accent};
  font-size: 1.3rem;
  float: right;
  color: #fff;
  &:disabled {
    cursor: default;
    opacity: 0.7;
  }
`;

const UserMarker = (props) => {
  // let linkInputHref = null;
  const {
    t,
    deleteMarker,
    markerPosition,
    toggleMarkerDrag,
    toggleShareModal,
    currentUserId,
    ownerId,
    markerId,
    parentId,
    markerText,
  } = props;
  const canEdit = ownerId === currentUserId || !ownerId;
  const [text, changeText] = useState(markerText || '');
  const [markersId, setMarkersId] = useState(null);
  const [editing, toggleEdit] = useState(canEdit);

  const toggleEditing = (isEdit) => {
    toggleEdit(isEdit);
    toggleMarkerDrag(isEdit);
  };

  const shareMarker = () => {
    changeURLPage(`markers/${markersId}`);
    toggleShareModal(true);
  };

  const saveMarker = async (markerDescription, position, markerParentId) => {
    if (!markerDescription || !markerDescription.length) return;
    const { lng, lat } = position;
    const form = new window.FormData();
    if (markerId) {
      form.append('id', markerId);
    }
    if (parentId) {
      form.append('parent_id', markerParentId);
    }
    form.append('owner_id', currentUserId);
    form.append('lat', lat);
    form.append('lng', lng);
    form.append('text', markerDescription);
    const json = await actionSaveUserMarker(form);
    if (json && json.parent_id) {
      setMarkersId(json.parent_id);
      toggleEditing(false);
    }
  };

  const deleteUserMarker = async (id, markerParentId) => {
    const form = new window.FormData();
    if (parentId) {
      form.append('parent_id', markerParentId);
    }
    if (id) {
      form.append('id', id);
      await actionDeleteUserMarker(form);
    }
  };

  useEffect(() => {
    if (markersId) {
      changeURLPage(`markers/${markersId}`, true);
    }
  }, [markersId]);

  return (
    <Wrapper>
      <Container>
        <HiddenInput type='text' />
        <Textarea
          onChange={e => changeText(e.target.value)}
          placeholder={t('write a note')}
          required
          defaultValue={markerText}
          disabled={!editing}
          autocomplete='off'
          autocorrect='off'
          autocapitalize='off'
          spellcheck='false'
          rows={5}
          cols={35}
        />
      </Container>
      {canEdit && (
        <Footer>
          {!editing && (
            <ShareButton data-rh={t('Link to marker')} onClick={() => shareMarker(true)}>
              <Tooltip>{t('Link to markers copied')}</Tooltip>
              <ShareButtonIcon className='icon-share' />
            </ShareButton>
          )}
          {!editing && <SaveButton onClick={() => toggleEditing(true)}>{t('edit')}</SaveButton>}
          {editing && (
            <SaveButton disabled={!text} onClick={() => saveMarker(text, markerPosition, parentId)}>
              {t('save')}
            </SaveButton>
          )}
          <DeleteButton
            onClick={() => {
              deleteUserMarker(markerId, parentId);
              deleteMarker();
              changeURLPage('');
            }}
          >
            {t('delete')}
          </DeleteButton>
        </Footer>
      )}
    </Wrapper>
  );
};

UserMarker.defaultProps = {
  parentId: null,
  ownerId: null,
  markerId: null,
  markerText: null,
};

UserMarker.propTypes = {
  t: PropTypes.func.isRequired,
  deleteMarker: PropTypes.func.isRequired,
  toggleShareModal: PropTypes.func.isRequired,
  currentUserId: PropTypes.string.isRequired,
  parentId: PropTypes.string,
  ownerId: PropTypes.string,
  markerId: PropTypes.string,
  markerText: PropTypes.string,
  toggleMarkerDrag: PropTypes.func.isRequired,
  markerPosition: PropTypes.object.isRequired,
  // router: PropTypes.object.isRequired,
};

export default UserMarker;
