import {
  createStore, combineReducers, applyMiddleware 
} from 'redux';
import thunkMiddleware from 'redux-thunk';
import reducers from './reducers';

const initStore = (initialState = {}) => {
  const combinedReducers = combineReducers(reducers);
  // const composeEnhancers =
  // typeof window === 'object' &&
  // window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__() : compose;
  const store = createStore(combinedReducers, initialState, applyMiddleware(thunkMiddleware));
  return store;
};

export default initStore;
