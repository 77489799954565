import * as types from '../types';

const tileStyles = (state = { mapStyles: null, sateliiteStyles: null, mapViewType: 'mapStyle' }, action) => {
  switch (action.type) {
    case types.GET_STYLE: {
      return { ...state, ...action.payload };
    }
    case types.SET_MAP_VIEW_TYPE: {
      return { ...state, mapViewType: action.payload };
    }
    default: {
      return state;
    }
  }
};

const map = (state = {
  isShareModalOpened: false, mode: '', user: {}, canInitToolsOnMount: true, showLevelBtns: false
}, action) => {
  switch (action.type) {
    case types.SET_MAP_MODE: {
      return {
        ...state,
        mode: state.mode !== action.payload ? action.payload : '',
      };
    }
    case types.FETCH_3D_OBJECTS: {
      return {
        ...state,
        objects3D: action.payload,
      };
    }
    case types.TOGGLE_BUILDING_LEVEL_BUTTONS: {
      return {
        ...state,
        showLevelBtns: action.payload,
      };
    }
    case types.TOGGLE_MAP_SHOW: {
      return {
        ...state,
        mapShow: action.payload,
      };
    }
    case types.SET_MAP_TOOLS_INITED: {
      return {
        ...state,
        canInitToolsOnMount: action.payload,
      };
    }
    case types.TOGGLE_SHARE_MODAL: {
      return {
        ...state,
        isShareModalOpened: action.payload,
      };
    }
    case types.SET_USER: {
      const { user, userAuth } = action.payload;
      return { ...state, ...{ user, userAuth } };
    }
    default: {
      return state;
    }
  }
};

const search = (state = { results: [], searchShow: false }, action) => {
  switch (action.type) {
    case types.GET_SEARCH_RESULTS: {
      return { ...state, results: action.payload.list, selected: action.payload.selected };
    }
    case types.TOGGLE_SEARCH_SHOW: {
      return {
        ...state,
        searchShow: action.payload,
      };
    }

    case types.TOGGLE_INHERITED_SEARCH_SHOW: {
      return {
        ...state,
        searchShowInherited: action.payload,
      };
    }
    case types.CLEAR_SEARCH_RESULTS: {
      return { ...state, results: [], selected: null };
    }
    default: {
      return state;
    }
  }
};

const views = (state = {}, action) => {
  switch (action.type) {
    case types.GET_SEARCH_RESULTS: {
      return { ...state, active: '' };
    }
    case types.CHANGE_VIEW: {
      return { ...state, active: action.payload };
    }
    default: {
      return state;
    }
  }
};

const routing = (state = { public: { routes: [] } }, action) => {
  switch (action.type) {
    case types.SET_PUBLIC_ROUTES: {
      return { ...state, public: action.payload };
    }
    case types.SET_DRIVING_ROUTES: {
      return { ...state, driving: action.payload };
    }
    case types.SET_WALKING_ROUTES: {
      return { ...state, walking: action.payload };
    }
    case types.SET_ORIGIN_ADDRESS: {
      return { ...state, originAddress: action.payload };
    }
    case types.SET_DESTINATION_ADDRESS: {
      return { ...state, destinationAddress: action.payload };
    }
    case types.CLEAR_ROUTES_STORE: {
      if (action.payload === 'origin') {
        return { originAddress: '', destinationAddress: state.destinationAddress };
      }
      if (action.payload === 'destination') {
        return { destinationAddress: '', originAddress: state.originAddress };
      }
      return {
        originAddress: '', destinationAddress: '', public: { routes: [] }
      };
    }
    default: {
      return state;
    }
  }
};

const selectedInitialState = {
  street: null,
  city: null,
  allBranches: [],
  branches: null
};

const selected = (state = selectedInitialState, action) => {
  switch (action.type) {
    case types.GET_STREET: {
      return { ...state, street: action.payload };
    }
    case types.GET_CITY: {
      return { ...state, city: action.payload };
    }
    case types.GET_CATEGORY: {
      return { ...state, category: action.payload };
    }
    case types.GET_CATEGORIES: {
      return { ...state, categories: action.payload };
    }
    case types.GET_SUBCATEGORIES: {
      return { ...state, subcategories: action.payload };
    }
    case types.GET_ROUTES: {
      return { ...state, ...{ routes: action.payload } };
    }
    case types.GET_ROUTE: {
      return { ...state, activeRoute: action.payload };
    }
    case types.GET_OBJECT: {
      return { ...state, activeObject: action.payload };
    }
    case types.GET_BRANCH: {
      return { ...state, activeObject: action.payload.points.branch };
    }
    case types.CLEAR_OBJECT: {
      return {
        ...state,
        activeObject: null,
        street: null,
        city: null,
      };
    }
    case types.GET_CATEGORY_ITEMS: {
      return {
        ...state,
        ...{
          categoryItems: action.payload.items,
          allBranches: action.payload.allBranches,
        },
      };
    }
    case types.CLEAR_CATEGORY_RESULTS: {
      return {
        ...state,
        ...{
          categoryItems: null,
          allBranches: [],
        },
      };
    }
    case types.GET_COMPANY_BRANCHES: {
      const oldBranches = state.branches || { points: [] };
      if (action.payload.onScroll) {
        return {
          ...state,
          branches: { ...action.payload, points: oldBranches.points.concat(action.payload.points) }
        }; 
      }
      return {
        ...state,
        branches: { ...action.payload, points: action.payload.points }
      };
    }
    case types.CLEAR_COMPANY_BRANCHES: {
      return { ...state, branches: selectedInitialState.branches };
    }
    default: {
      return state;
    }
  }
};

const reducers = {
  tileStyles,
  map,
  routing,
  selected,
  search,
  views,
};

export default reducers;
