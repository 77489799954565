import React, { Fragment } from 'react';
import styled from 'styled-components';

const IconWrap = styled.div`
  border-radius: 50%;
  border: 2px solid #fff;
  box-shadow: 0 2px 7px 0 rgba(255,130,1,0.25);
  color: #fff;
  background-color: #ff7201;
  width: 30px;
  height: 30px;
`;

const MarkerIcon = styled.span`
  font-size: 2.0rem;
  margin: 0 3px;
  top: 3px;
  position: relative;
`;

const PlaceName = styled.span`
  height: 24px;
  overflow: hidden;
  align-self: center;
  max-width: 238px;
  vertical-align: -8px;
  border-radius: 14px;
  background-color: #fff;
  box-shadow: 0 0 4px 0 rgba(0,0,0,.05), 0 1px 2px 0 rgba(94,94,94,.21);
  color: rgba(0,0,0,.87);
  font-weight: 600;
  line-height: 15px;
  text-align: center;
  padding: 4px 15px 5px 37px;
  margin-left: -30px;
  position: relative;
  z-index: -1;
  white-space:nowrap;
  text-overflow: ellipsis;
`;

const MapPlaceMarker = () => (
  <Fragment>
    <IconWrap>
      <MarkerIcon className='icon-place' />
    </IconWrap>
    <PlaceName className='MarkerAddres'>PlaceUndef</PlaceName>
  </Fragment>
);

export default MapPlaceMarker;
