import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Link } from '@/routes';
import MyLocation from './components/MyLocation';
import MarkerTool from './components/MarkerTool';
import PanTool from './components/PanTool';
import RulerTool from './components/RulerTool';
import ShareTool from './components/ShareTool';
import PrintTool from './components/PrintTool';
import InfoTool from './components/InfoTool';
import YearTool from './components/YearTool';

const Wrapper = styled.div`
  position: absolute;
  right: 14px;
  top: 15px;
  z-index: 1000;
  & button {
    margin-top: 0;
    margin-bottom: 0;
  }
  ${({ theme }) => theme.onSM(`
      display: none;
  `)};
`;

const MyLocationWrapper = styled.span`
  background-color: #ffffff;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.1), 0 3px 10px 0 rgba(0, 0, 0, 0.07);
  border-radius: 100px;
  display: inline-block;
  vertical-align: middle;
  margin-right: 12px;
  & > * {
    display: inline-block;
    vertical-align: middle;
  }
  &:last-of-type {
    margin-right: 0;
  }
`;

const NeampornitLink = styled.a`
  display: inline-block;
  vertical-align: middle;
  padding: 4px 15px 4px 5px;
  margin-right: 12px;
  border-radius: 100px;
  background-color: #06453F;
  box-shadow: 0 2px 4px 0 rgba(0,0,0,0.1), 0 3px 10px 0 rgba(0,0,0,0.07);
`;

const NeampornitIcon = styled.img`
  margin-right: 10px;
  display: inline-block;
  vertical-align: middle;
`;

const NeampornitLogo = styled.img`
display: inline-block;
  vertical-align: middle;
`;

const ToolWrapper = styled.span`
  background-color: #ffffff;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.1), 0 3px 10px 0 rgba(0, 0, 0, 0.07);
  border-radius: 100px;
  display: inline-block;
  vertical-align: middle;
  margin-right: 12px;
  padding: 0 5px;
  & > * {
    display: inline-block;
    vertical-align: middle;
  }
  &:last-of-type {
    margin-right: 0;
  }
`;

const ToolSeparator = styled.div`
  width: 1px;
  background: #ededed;
  height: 18px;
  margin: auto;
`;

// const EXCLUDE_NEAMPORNIT = ['places'];

const Toolbar = (props) => {
  const { MapGL, urlParams } = props;
  // const { pageName } = urlParams || {};
  const i18n = useTranslation()[1];
  const lang = i18n.language || 'ro';
  // const hideNeampornit = EXCLUDE_NEAMPORNIT.indexOf(pageName) > -1;
  const hideNeampornit = true;
  // const date = new Date();
  // const currentYear = date.getFullYear();
  
  return (
    <Wrapper>
      {!hideNeampornit && (
        <Link route={`/${lang}/places/neampornit#10/47.0439/28.8538`}>
          <NeampornitLink>
            <NeampornitIcon src='/static/images/neam_icon.svg' />
            {lang === 'en' ? (
              <NeampornitLogo src='/static/images/beourguest.svg' />
            ) : (
              <NeampornitLogo src='/static/images/neam_logo.svg' />
            )}
          </NeampornitLink>
        </Link>
      )}
      <ToolWrapper>
        <YearTool urlParams={urlParams} year='1880' />
        <YearTool urlParams={urlParams} year='1940' />
        <YearTool urlParams={urlParams} year='1960' />
        <YearTool urlParams={urlParams} year='2020' />
      </ToolWrapper>
      <MyLocationWrapper>
        <MyLocation />
      </MyLocationWrapper>
      <ToolWrapper>
        <PanTool MapGL={MapGL} />
        <ToolSeparator />
        <MarkerTool MapGL={MapGL} />
        <ToolSeparator />
        <RulerTool MapGL={MapGL} />
      </ToolWrapper>
      <ToolWrapper>
        <ShareTool MapGL={MapGL} />
        <ToolSeparator />
        <PrintTool />
        <ToolSeparator />
        {/* <InfoTool /> */}
      </ToolWrapper>
    </Wrapper>
  );
};

Toolbar.defaultProps = {
  urlParams: {
    subpage: '',
    pageName: ''
  }
};

Toolbar.propTypes = {
  MapGL: PropTypes.object.isRequired,
  urlParams: PropTypes.shape({
    subpage: PropTypes.string,
    pageName: PropTypes.string
  }),
};

export default Toolbar;
