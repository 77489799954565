import React, { Fragment, useEffect } from 'react';
import App from 'next/app';
import dynamic from 'next/dynamic';
import styled, { ThemeProvider } from 'styled-components';
import PropTypes, { instanceOf } from 'prop-types';
import ReactHintFactory from 'react-hint';
import { I18nextProvider, withTranslation } from 'react-i18next';
import { Cookies } from 'react-cookie';
import { Provider } from 'react-redux';
import Router from 'next/router';
import 'react-awesome-slider/dist/styles.css';
import { MapCtx } from '@/src/contexts';
import ViewSwitcher from '@/src/components/ViewSwitcher';
import Logo from '@/src/components/Logo';
import withReduxStore from '@/src/HOC/withReduxStore';
import appTheme from '@/src/theme';
import createI18n from '@/src/i18n';
// import Topbar from '@/src/components/Topbar';
import {
  // getMyUserAccount, 
  changeURLPage
} from '@/src/helpers';
import Map from '@/src/components/Map';
import objects3DRender from '@/src/3DObjects';
import Viewport from '@/src/components/Viewport';
import withMapTools from '@/src/HOC/withMapTools';
import {
  getStyles,
  get3DObjects,
  // setUser,
} from '@/src/redux/actions';

const ReactHint = ReactHintFactory(React);

const GTMNoSSR = dynamic(() => import('@/src/components/GTM'), {
  loading: () => null,
  ssr: false,
});

// const NumbersHorizontalNoSSR = dynamic(() => import('@/src/components/NumbersHorizontal'), {
//   loading: () => null,
//   ssr: false,
// });

// const SMART_BANNER_CONFIG = {
//   daysHidden: 15, // days to hide banner after close button is clicked (defaults to 15)
//   daysReminder: 90, // days to hide banner after "VIEW" button is clicked (defaults to 90)
//   appStoreLanguage: 'ru', // language code for the App Store (defaults to user's browser language)
//   title: 'Map.md',
//   author: 'Simpals',
//   button: 'Install',
//   store: {
//     ios: null,
//     android: 'Google Play',
//     windows: null,
//   },
//   price: {
//     ios: '',
//     android: '',
//     windows: '',
//   },
//   // theme: 'ios', // put platform type ('ios', 'android', etc.) here to force single theme on all device
//   icon: '/favicon/apple-touch-icon.png', // full path to icon image if not using website icon image
//   // force: 'android', // Uncomment for platform emulation
// };

const PointWrap = styled.div`
  min-height: 100%;
  height: 100%;
  min-width: 100%;
  position: absolute;
  overflow: hidden;
`;

// const NumbersWrapper = styled.div`
//   height: 90px;
//   ${({ theme }) => theme.onSM(`
//     display:none;
//   `)}
// `;

const MainWrapper = styled.div`
  position: relative;
  height: 100%;
  &:not(.is-embed) {
    // height: calc(100% - 90px);
    height: 100%;
    ${({ theme }) => theme.onSM(`
      flex: 1 0 auto;
      height: auto;
    `)}
  }
`;
const LayoutWrapper = styled.div`
  position: relative;
  overflow: hidden;
  overflow-y: ${({ isBlog }) => (isBlog ? 'auto' : 'hidden')};
  font-size: 0;
  height: 100%;
  &.is-embed-print {
    padding-top: 0;
  }
  &:not(.is-embed-print) {
    @media screen and (max-width: 850px) ,
    only screen and ( min-resolution: 200dpi) {
      padding-top: 0px;
    }
    
    height: 100%;
    & ${MainWrapper} {
      // height: calc(100% - 90px);
      ${({ theme }) => theme.onSM(`
        // height: 100%;
        
        position:relative;
        // overflow: hidden;
      `)}
    }
  }
  ${({ theme }) => theme.onSM(`
    display: flex;
    flex-direction: column;
    padding-top: 0 !important;
    height: 100%;
  `)}
`;
const Layout = (props) => {
  const {
    children,
    urlParams: { embed, print, pageName },
  } = props;
  useEffect(() => {
    if (embed || print) {
      document.querySelector('body').classList.remove('has-top-bar');
    } else {
      document.querySelector('body').classList.add('has-top-bar');
    }
  }, []);
  return (
    <LayoutWrapper 
      className={(embed || print) && 'is-embed-print'}
      isBlog={pageName && pageName.match(/blog|info|api/)}
    >
      {children}
    </LayoutWrapper>
  );
};

Layout.propTypes = {
  children: PropTypes.any.isRequired,
  urlParams: PropTypes.object.isRequired,
};

const validateLang = (lang, queryLang) => {
  const langRegExp = /(ru|ro|en)/g;
  if (!lang && queryLang) {
    if (langRegExp.exec(queryLang)) {
      return queryLang;
    }
  }
  if (langRegExp.exec(lang)) {
    return lang;
  }
  return 'ru';
};

const MainComponent = (props) => {
  const {
    t, Component, pageProps, urlParams, mapStyle, addListener, removeListeners 
  } = props;
  return (
    <Fragment>
      <Component {...pageProps} t={t} urlParams={urlParams} />
      <Viewport
        mapStyle={mapStyle}
        urlParams={{ ...urlParams }}
        t={t}
        mapListeners={{
          removeListeners,
          addListener
        }}
      />
    </Fragment>
  );
};

MainComponent.defaultProps = {
  pageProps: {},
  urlParams: {},
};

MainComponent.propTypes = {
  t: PropTypes.func.isRequired,
  pageProps: PropTypes.object,
  urlParams: PropTypes.object,
  mapStyle: PropTypes.object.isRequired,
  addListener: PropTypes.func.isRequired,
  removeListeners: PropTypes.func.isRequired,
  Component: PropTypes.oneOfType([PropTypes.func, instanceOf(React.Component)]).isRequired,
};

const EnhancedComponent = withMapTools(MainComponent);

const MapOrPageHOC = (props) => {
  const {
    pageName, Component, stateMapGL, pageProps 
  } = props;
  const hideMap = ['blog', 'api', 'info'].indexOf(pageName) > -1;
  if (hideMap) {
    return (
      <Component {...props} {...pageProps} />
    );
  }
  return (
    <Fragment>
      <PointWrap>
        <Map
          {...props}
          {...pageProps}
        />
      </PointWrap>
      {stateMapGL && <EnhancedComponent {...props} stateMapGL={stateMapGL} pageProps={pageProps} />}
    </Fragment>
  );
};

MapOrPageHOC.defaultProps = {
  pageName: '',
  stateMapGL: null,
};

MapOrPageHOC.propTypes = {
  pageName: PropTypes.string,
  stateMapGL: PropTypes.object,
  pageProps: PropTypes.object.isRequired,
  Component: PropTypes.oneOfType([PropTypes.func, instanceOf(React.Component)]).isRequired,
};

const MapOrPage = withTranslation()(MapOrPageHOC);

// const YEARS_PROXY = {
//   1960: '1966',
//   1940: '1940'
// };
class MyApp extends App {
  constructor() {
    super();
    this.state = {
      stateMapGL: {
        mock: true,
        addLayer: () => null,
        flyTo: () => null,
        getPitch: () => null,
        queryRenderedFeatures: () => null,
        hasImage: () => null,
        loadImage: () => null,
        addImage: () => null,
        addControl: () => null,
        getSource: () => null,
        on: () => null,
        off: () => null,
        once: () => null,
        addSource: () => null,
        areTilesLoaded: () => false,
        isStyleLoaded: () => false,
        getLayer: () => null,
        setLayoutProperty: () => null,
        fitBounds: () => null,
      },
      appInited: false 
    };
  }

  static async getInitialProps(mainContext) {
    const { ctx } = mainContext;
    const {
      req, query, reduxStore 
    } = ctx;
    const {
      viewType 
    } = query;
    const Component = mainContext.Component;
    let pageProps = {};
    let cookie;
    if (Component.getInitialProps) {
      pageProps = await Component.getInitialProps(ctx);
    }
    let year = query.year;

    switch (query.year) {
      case '1960': {
        year = '1966';
        break;
      }
      case '1940': {
        year = '1940';
        break;
      }
      default: {
        year = query.year;
        break;
      }
    }
    // if (req) {
    //   const { account, userAuth } = await getMyUserAccount(req);
    //   if (account) reduxStore.dispatch(setUser({ user: account.user, userAuth }));
    // }
    // let styles = reduxStore.getState().tileStyles.mapStyles;
    // if (!objects3D) {
    const objects3DResp = await reduxStore.dispatch(get3DObjects(year, query.visible));
    const objects3D = objects3DResp && objects3DResp.payload;
    // }
    // if (!styles) {
    const styles = await reduxStore.dispatch(getStyles(year));
    // }
    let key = 'mapStyles';
    if (viewType === 'sateliteStyle') {
      key = 'sateliteStyles';
    }
    const mapStyle = (styles && styles.payload) ? styles.payload[key] : styles;
    if (req && req.headers) {
      cookie = req.headers.cookie;
    } else {
      cookie = typeof document !== 'undefined' ? document.cookie : '';
    }
    const cookies = new Cookies(cookie);
    const cookieLang = cookies.get('simpalsid.lang');
    // await langRedirect(res, req, cookieLang, query.lang || cookieLang);
    const lang = validateLang(cookieLang, query.lang);
    return {
      cookies,
      lang,
      mapStyle,
      objects3D,
      urlParams: query,
      pageProps,
    };
  }

  async componentDidMount() {
    // const { urlParams: { embed } } = this.props;
    // if (embed) return;
    // eslint-disable-next-line global-require
    // const SmartBanner = require('@/public/static/vendor/smart-app-banner/index.js');
    // const banner = new SmartBanner(SMART_BANNER_CONFIG);
    // return banner;
  }

  setMapCtx = (ctx) => {
    this.setState({
      stateMapGL: ctx,
      appInited: true
    });
  }

  onMapLoad = (MapGL) => {
    this.setMapCtx(MapGL);
  };

  onStyleData = (MapGL, e) => {
    const { urlParams: { viewType }, objects3D, lang } = this.props;
    const { style } = e;
    const pitch = MapGL.getPitch();
    if (viewType === 'sateliteStyle' || (style && style.stylesheet && style.stylesheet.id && style.stylesheet.id.indexOf('raster') > -1)) return;
    objects3DRender(MapGL, objects3D, lang, pitch);
  };

  removeListeners = (MapGL) => {
    MapGL.off('load', () => this.onMapLoad(MapGL));
  };

  addListener = (MapGL) => {
    Router.beforePopState((urlObject) => {
      const { url, as } = urlObject;
      if (!url || !as) {
        changeURLPage('');
        return false;
      }
      return true;
    });
    MapGL.on('style.load', () => this.onMapLoad(MapGL));
    MapGL.on('styledata', e => this.onStyleData(MapGL, e));
  };

  render() {
    const { stateMapGL } = this.state;
    const {
      Component, pageProps, lang, urlParams, reduxStore, mapStyle, objects3D
    } = this.props;
    const { embed, print, pageName } = urlParams;
    const i18n = createI18n(lang);

    return (
      <Fragment>
        <GTMNoSSR />
        <ThemeProvider theme={appTheme}>
          <I18nextProvider i18n={i18n} initialLanguage={lang}>
            <Provider store={reduxStore}>
              <MapCtx.Provider value={{ MapGL: stateMapGL, objects3D }}>
                <Layout urlParams={urlParams}>
                  <MainWrapper className={(embed || print) && 'is-embed'}>
                    {pageName !== '/api' && <Logo urlParams={urlParams} />}
                    <ReactHint position='bottom' events />
                    <MapOrPage
                      pageName={pageName}
                      stateMapGL={stateMapGL}
                      mapStyle={mapStyle}
                      Component={Component}
                      addListener={this.addListener}
                      removeListeners={this.removeListeners}
                      urlParams={urlParams}
                      pageProps={pageProps}
                    />
                  </MainWrapper>
                  {stateMapGL && !embed && !print && <ViewSwitcher isBottom pageName={pageName} />}
                </Layout>
              </MapCtx.Provider>
            </Provider>
          </I18nextProvider>
        </ThemeProvider>
        
      </Fragment>
    );
  }
}

MyApp.defaultProps = {
  pageProps: {}
};

MyApp.propTypes = {
  lang: PropTypes.string.isRequired,
  urlParams: PropTypes.object.isRequired,
  reduxStore: PropTypes.object.isRequired,
  pageProps: PropTypes.any,
  Component: PropTypes.oneOfType([PropTypes.func, instanceOf(React.Component)]),
};

export default withReduxStore(MyApp);
