import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { withRouter } from 'next/router';

const ToolsWrap = styled.div`
  display: flex;
  width: 100%;
  min-width: 600px;
  max-width: 800px;
  padding: 30px;
  ${({ theme }) => theme.onSM(`
    flex-direction: column;
    min-width: unset;
  `)}
`;

const IframeWrap = styled.div`
  display: flex;
  height: ${({ MapSizeH }) => MapSizeH}px;
  width: ${({ MapSizeW }) => MapSizeW}px;
  margin: auto;
  font-size: 0;
  border-radius: 0 0 10px 10px;
  position: relative;
  overflow: hidden;
`;

const SizeBlock = styled.div`
  width: 50%;
  max-width: 600px;
  font-size: 1.2rem;
  ${({ theme }) => theme.onSM(`
    width: 100%
  `)}
`;

const IframeUrlBlcok = styled.div`
  width: 50%;
  max-width: 600px;
  vertical-align: top;
  ${({ theme }) => theme.onSM(`
    width: 100%
  `)}
`;

const BlockTitle = styled.h5`
  height: 14px;
  line-height: 14px;
  font-size: 1.2rem;
  font-weight: 500;
  opacity: 0.4;
  color: #000;
`;

const SizeList = styled.ul`
  margin: 10px 0 0;
  padding: 0;
  list-style: none;
`;

const CheckLabel = styled.label`
  & > * {
    display: inline-block;
    vertical-align: middle;
  }
`;

const SizeInput = styled.input`
  margin: 0;
`;

const SizeSettings = styled.span`
  width: 80%;
  padding: 5px 0;
  margin-left: 20px;
  border-top: 1px solid #ededed;
  font-size: 100%;
  span {
    color: #999;
    font-size: 1.3rem;
  }
  p {
    width: 91px;
    opacity: 0.87;
    color: #000;
    font-size: 1.3rem;
    overflow: hidden;
  }
  & > * {
    display: inline-block;
    vertical-align: middle;
  }
`;

const CustomSize = styled.input`
  height: 22px;
  width: 40px;
  margin: 0 2px;
  border: none;
  border-radius: 2px;
  background-color: #f5f5f5;
  color: rgba(0, 0, 0, 0.87);
  font-size: 1.3rem;
  text-align: center;
  outline: none;
  &::-webkit-inner-spin-button,
  ::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  -moz-appearance: textfield;
  appearance: textfield;
`;

const IframeTextArea = styled.textarea`
  width: 100%;
  height: 192px;
  padding: 20px;
  margin-top: 6px;
  resize: none;
  font-size: 1.3rem;
  outline: 0;
  border-radius: 4px;
  border: none;
  box-sizing: border-box;
  color: rgba(0, 0, 0, 0.87);
  background-color: #f5f5f5;
`;

const ShowHideElement = styled.div`
  margin: 10px 0;
`;

const ShowHidePopup = styled.div`
  margin-top: 10px;
`;

const RadioButton = styled.input`
  cursor:pointer;
  margin:0 20px 0 0;
`;

const CustomRadioLabel = styled.label`
  cursor:pointer;
  margin:0 30px 0 0;
`;

const IncorrectData = styled.span`
  color: red;
`;

const sizes = {
  size_1: [300, 300],
  size_2: [425, 350],
  size_3: [600, 550],
};

class IframeLink extends Component {
  constructor(props) {
    super(props);
    this.WidthRef = React.createRef();
    this.iframeRef = React.createRef();
    this.HeightRef = React.createRef();
    this.customCheck = React.createRef();
    this.state = {
      MapSizeW: 600,
      MapSizeH: 550,
      iframeSrcCode: '',
      hidePopup: false,
      customSizeValid: true,
      customSize: false
    };
  }

  componentDidMount() {
    if (!this.iframeRef.current || !this.iframeRef.current.contentWindow) return;
    this.iframeRef.current.contentWindow.addEventListener('message', this.handleViewportChange);
  }

  saveData = ({ ...rest }) => {
    this.customCheck.current.click();
    this.setState({ ...rest });
  };

  checkValues = (width, height) => {
    const toSave = {
      customSizeValid: true,
      customSize: true
    };
    if (width < 250 || height < 250) {
      toSave.customSizeValid = false;
    }
    if (width >= 250) {
      toSave.MapSizeW = width;
    }
    if (height >= 250) {
      toSave.MapSizeH = height;
    }
    return toSave;
  }

  handleViewportChange = (e) => {
    const { hidePopup } = this.state;
    if (e && e.data) {
      try {
        const data = JSON.parse(e.data);
        if (typeof data === 'object' && data.viewport) {
          const {
            zoom, pitch, bearing, center 
          } = data.viewport;
          this.setState({
            iframeSrcCode:
            `${window.location.origin}${window.location.pathname}${window.location.search 
              ? `${window.location.search}&embed=1`
              : '?embed=1'}${hidePopup
              ? '&hidePopup=1'
              : ''}#${zoom}/${center.lat}/${center.lng}/${bearing}/${pitch}`
          });
        }
      // eslint-disable-next-line no-empty
      } catch (err) {}
    }
  }  

  handleSize = (e) => {
    const { id } = e.target;
    const {
      WidthRef, HeightRef, saveData, checkValues
    } = this;
    let MapSizeW = 0;
    let MapSizeH = 0;
    switch (id) {
      case 'custom':
      case 'customHeight':
      case 'customWidth': {
        MapSizeW = parseInt(WidthRef.current.value, 10);
        MapSizeH = parseInt(HeightRef.current.value, 10);
        saveData(checkValues(MapSizeW, MapSizeH));
        break;
      }
      default: {
        this.setState({
          MapSizeW: sizes[id][0],
          MapSizeH: sizes[id][1],
          customSize: false,
        });
      }
    }
  };

  render() {
    const {
      props: { t, router: { query } },
      state: {
        MapSizeW, MapSizeH, customSizeValid, customSize, iframeSrcCode, hidePopup
      },
      handleSize,
      customCheck,
      HeightRef,
      WidthRef,
    } = this;
    const iframeSrc = `${window.location.origin}${window.location.pathname}${window.location.search 
      ? `${window.location.search}&embed=1`
      : '?embed=1'}${hidePopup
      ? '&hidePopup=1'
      : ''}${window.location.hash}`;
    
    return (
      <Fragment>
        <ToolsWrap>
          <SizeBlock>
            <BlockTitle>{t('size')}</BlockTitle>
            <SizeList onChange={event => handleSize(event)}>
              <li>
                <CheckLabel htmlFor='size_1'>
                  <SizeInput type='radio' name='size' id='size_1' />
                  <SizeSettings>
                    <p>{t('small')}</p>
                    <span>300 x 300</span>
                  </SizeSettings>
                </CheckLabel>
              </li>
              <li>
                <CheckLabel htmlFor='size_2'>
                  <SizeInput type='radio' name='size' id='size_2' />
                  <SizeSettings>
                    <p>{t('medium')}</p>
                    <span>425 x 350</span>
                  </SizeSettings>
                </CheckLabel>
              </li>
              <li>
                <CheckLabel htmlFor='size_3'>
                  <SizeInput type='radio' name='size' id='size_3' />
                  <SizeSettings>
                    <p>{t('big')}</p>
                    <span>600 x 550</span>
                  </SizeSettings>
                </CheckLabel>
              </li>
              <li>
                <CheckLabel htmlFor='custom'>
                  <SizeInput type='radio' name='size' id='custom' ref={customCheck} defaultChecked />
                  <SizeSettings>
                    <p>{t('custom')}</p>
                    <span>
                      <CustomSize
                        id='customWidth'
                        type='number'
                        ref={WidthRef}
                        defaultValue={MapSizeW || 0}
                      />
                      x
                      <CustomSize
                        id='customHeight'
                        type='number'
                        ref={HeightRef}
                        defaultValue={MapSizeH || 0}
                      />
                    </span>
                  </SizeSettings>
                </CheckLabel>
              </li>
            </SizeList>
            {!customSizeValid && customSize && <IncorrectData>{t('incorrect data')}</IncorrectData>}
            {query.markersId && (
              <ShowHidePopup>
                <ShowHideElement>
                  <RadioButton
                    id='show-popup'
                    type='radio'
                    name='hidePopup'
                    defaultChecked
                    onChange={(e) => {
                      if (e.currentTarget.checked) {
                        this.setState({ hidePopup: false });
                      }
                    }}
                  />
                  <CustomRadioLabel htmlFor='show-popup'>
                    {t('Show popup')}
                  </CustomRadioLabel>
                </ShowHideElement>
                <ShowHideElement>
                  <RadioButton
                    id='hide-popup'
                    type='radio'
                    name='hidePopup'
                    onChange={(e) => {
                      if (e.currentTarget.checked) {
                        this.setState({ hidePopup: true });
                      }
                    }}
                  />
                  <CustomRadioLabel htmlFor='hide-popup'>
                    {t('Hide popup')}
                  </CustomRadioLabel>
                </ShowHideElement>
              </ShowHidePopup>
            )}
          </SizeBlock>
          <IframeUrlBlcok>
            <BlockTitle>{t('insertcode')}</BlockTitle>
            <IframeTextArea
              // eslint-disable-next-line max-len
              value={`<iframe allow="geolocation" width=${MapSizeW} height=${MapSizeH} frameborder="0" src="${iframeSrcCode || iframeSrc}"> </iframe>`}
              onFocus={e => e.target.select()}
              readOnly
            />
          </IframeUrlBlcok>
        </ToolsWrap>
        <IframeWrap MapSizeW={MapSizeW} MapSizeH={MapSizeH}>
          <iframe
            title='Map.md Embed'
            ref={this.iframeRef}
            allow='geolocation'
            width={MapSizeW}
            height={MapSizeH}
            frameBorder='0'
            src={iframeSrc}
          />
        </IframeWrap>
      </Fragment>
    );
  }
}

IframeLink.propTypes = {
  t: PropTypes.func.isRequired,
  router: PropTypes.object.isRequired,
};

export default withRouter(IframeLink);
