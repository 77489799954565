import React from 'react';
import styled from 'styled-components';
import theme from '@/src/theme';

const Wrapper = styled.div``;

const MarkerIcon = styled.img`
  line-height:0;
  color: ${theme.colors.accent};
  font-size: 3.0rem;
`;

const BuildingMarkerIcon = () => (
  <Wrapper>
    <MarkerIcon src='/static/images/building-point.svg' />
  </Wrapper>
);

export default BuildingMarkerIcon;
