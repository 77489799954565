import React from 'react';
import PropTypes from 'prop-types';
import { MapCtx } from '@/src/contexts';

export default (Component) => {
  const WithMap = props => (
    <MapCtx.Consumer>{({ MapGL }) => <Component {...props} MapGL={MapGL} />}</MapCtx.Consumer>
  );

  WithMap.defaultProps = {
    tReady: false,
    i18n: null,
    street: null,
    t: a => a,
    dispatch: a => a,
  };

  WithMap.propTypes = {
    tReady: PropTypes.bool,
    i18n: PropTypes.object,
    street: PropTypes.object,
    dispatch: PropTypes.func,
    t: PropTypes.func,
  };

  return WithMap;
};
