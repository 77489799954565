import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import styled from 'styled-components';
import { INITIAL_LEVEL_ON_LEVELED_BUILDING } from '@/constants';

const LevelButtons = styled.div`
  position: absolute;
  right: 15px;
  top: 34%;
  overflow:hidden;
  background-color: #ffffff;
  border-radius: 100px;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.1), 0 3px 10px 0 rgba(0, 0, 0, 0.07);
  ${({ theme }) => theme.onSM(`
      right: 8px;
      top:133px;
    `)}
`;

const LevelButton = styled.button`
  width:36px;
  padding: 8.5px 0px;
  position: relative;
  display:block;
  text-align: center;
  font-size: 16px;
  font-family: "SF Pro Display";
  color: #808080;
  font-weight: 600;
  &:first-of-type {
    padding-top:16px;
  }
  &:after {
    content:'';
    position: absolute;
    bottom:0;
    background-color: #ededed;
    left:0;
    right:0;
    height: 1px;
    width: 18px;
    margin: auto;
  }
  &:last-of-type {
    padding-bottom:16px;
    &:after {
      display: none;
    }
  }
  &.active {
    color: #fff;
    background-color: #808080;
    &:after {
      background-color: #808080;
    }
  }
`;

const POI_LAYERS_SELECTOR = [
  'vector-poi-withclass-lvl-P',
  'vector-poi-withclass-lvl',
  'vector-poi-subclass-lvl',
  'vector-poi-subclass-lvl-P'
];

const LevelsButtons = (props) => {
  const { MapGL, showLevelBtns } = props;
  const [currentLevel, setCurrentLevel] = useState(INITIAL_LEVEL_ON_LEVELED_BUILDING);
  let poiLayers = null;
  if (MapGL.getLayer(POI_LAYERS_SELECTOR[0])) {
    poiLayers = MapGL.queryRenderedFeatures(
      null, 
      {
        layers: POI_LAYERS_SELECTOR
      }
    );
  }

  const changeActiveLevel = (level) => {
    const curLayerSelector = `vector-building-parts-${currentLevel}`;
    const layerSelector = `vector-building-parts-${level}`;

    const curLayer = MapGL.getLayer(curLayerSelector);
    const layer = MapGL.getLayer(layerSelector);
    if (curLayer) {
      MapGL.setPaintProperty(curLayerSelector, 'fill-extrusion-opacity', 0);
    }
    if (layer) {
      MapGL.setPaintProperty(layerSelector, 'fill-extrusion-opacity', 0.5);
    }
    
    if (poiLayers) {
      poiLayers.map((poi) => {
        MapGL.setFeatureState(
          poi,
          { hidden: true }
        );
        if (poi.properties && poi.properties.level === level) {
          MapGL.setFeatureState(
            poi,
            { hidden: false }
          );
        }
      });
    }

    setCurrentLevel(level);
  };

  // const togglePoiLayerOverlap = (isOverlap) => {
  //   POI_LAYERS_SELECTOR.map((poiLayerSelector) => {
  //     MapGL.setLayoutProperty(poiLayerSelector, 'icon-allow-overlap', isOverlap);
  //     MapGL.setLayoutProperty(poiLayerSelector, 'text-allow-overlap', isOverlap);
  //   });
  // }

  useEffect(() => {
    const curLayerSelector = `vector-building-parts-${currentLevel}`;
    const curLayer = MapGL.getLayer(curLayerSelector);
    
    if (curLayer) {
      MapGL.setPaintProperty(curLayerSelector, 'fill-extrusion-opacity', 0.5);
    }
    if (poiLayers) {
      poiLayers.map((poi) => {
        // togglePoiLayerOverlap(false);
        MapGL.setFeatureState(
          poi,
          { hidden: true }
        );
        if (poi.properties && poi.properties.level === currentLevel) {
          // togglePoiLayerOverlap(true);
          MapGL.setFeatureState(
            poi,
            { hidden: false }
          );
        }
      });
    }
  });

  if (!showLevelBtns) return null;
  
  const lvlBtnsArray = showLevelBtns.map(lvl => lvl);
  
  return (
    <LevelButtons>
      {lvlBtnsArray.map(i => (
        <LevelButton className={i === currentLevel && 'active'} key={i} onClick={() => changeActiveLevel(i)}>
          {i}
        </LevelButton>
      ))}
    </LevelButtons>
  );
};

LevelsButtons.defaultProps = {
  showLevelBtns: null
};
LevelsButtons.propTypes = {
  MapGL: PropTypes.object.isRequired,
  showLevelBtns: PropTypes.any
};

const mapStateToProps = state => ({
  showLevelBtns: state.map.showLevelBtns
});

export default connect(mapStateToProps)(LevelsButtons);
