import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import intervalPlural from 'i18next-intervalplural-postprocessor';
import ru from './translations/ru.json';
import ro from './translations/ro.json';
import en from './translations/en.json';

const resources = {
  ru: {
    translation: ru,
  },
  ro: {
    translation: ro,
  },
  en: {
    translation: en,
  },
};

const createI18n = (lng) => {
  i18n
    .use(initReactI18next)
    .use(intervalPlural)
    .init({
      resources,
      lng,
      keySeparator: false,
      interpolation: {
        escapeValue: false,
      },
    });
  return i18n;
};

export default createI18n;
