import React, { memo, useContext, Fragment } from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import MapControls from '@/src/components/MapControls';
import { MapCtx } from '@/src/contexts';

const AttributionBlock = styled.div`
  display: none;
  position: fixed;
  bottom: 15px;
  right: 76px;
  text-shadow: 1px 1px rgba(255, 255, 255, 0.89);
  font-size: 1.1rem;
  &:not(.is-embed) {
    display: block;
    ${({ theme }) => theme.onSM(`
      right: 8px;
      bottom: 64px;
    `)}
  };
`;

const AttributionLink = styled.a`
  color: #333;
  text-decoration: none;
  text-shadow: 1px 1px rgba(255, 255, 255, 0.89);
  font-size: 1.1rem;
  cursor: pointer;
  &.usgs {
    position: fixed;
    left: 15px;
  }
`;

const Viewport = memo((props) => {
  const { urlParams } = props;
  const {
    embed, print, year, viewType 
  } = urlParams;
  const { MapGL } = useContext(MapCtx);
  
  const hideOSM = (!year || ['1940', '1960'].indexOf(year) > -1) && viewType === 'sateliteStyle';
  return (
    <Fragment>
      {MapGL && <MapControls urlParams={urlParams} MapGL={MapGL} />}
      <AttributionBlock className={(embed || print) && 'is-embed'}>
        <AttributionLink href='https://simpals.com' target='_blank'>
          Simpals
        </AttributionLink>
        {!hideOSM && (
          <AttributionLink href='https://www.openstreetmap.org/#map=13/47.0124/28.8474' target='_blank'>
            &nbsp;© OpenStreet
          </AttributionLink>
        )}
        &nbsp;
        {year === '1960' && (
          <AttributionLink className='usgs' href='https://www.usgs.gov/' target='_blank'>
            U.S. Geological Survey
          </AttributionLink>
        )}
      </AttributionBlock>
      
    </Fragment>
  );
});

Viewport.propTypes = {
  urlParams: PropTypes.object.isRequired,
};

const mapStateToProps = state => ({
  mapMode: state.map.mode,
});

export default connect(mapStateToProps)(Viewport);
