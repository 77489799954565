import React from 'react';
import PropTypes from 'prop-types';
import ReactDOM from 'react-dom';
import fetch from 'isomorphic-fetch';
import Hammer from '@egjs/hammerjs';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import { changeURLPage, addQueryStringToUrl, hostResolver } from '@/src/helpers';
import {
  AUTHORIZATION, GET_NEAR_URL, OSRM_URL, PUBLIC_ROUTES_URL 
} from '@/constants';
import withMap from '@/src/HOC/withMap';
import RoutingDirectionPopup from '@/src/components/RoutingDirectionPopup';
import routingStyles from '@/public/static/routingStyles';
import {
  setPublicRoutes,
  setDrivingRoutes,
  setWalkingRoutes,
  setOriginAddress,
  setDestinationAddress,
} from '@/src/redux/actions';

export default (Component) => {
  let mapboxgl = null;
  // let MapboxDirections = null;
  if (typeof window !== 'undefined') {
    // eslint-disable-next-line global-require
    mapboxgl = require('mapbox-gl');
    // eslint-disable-next-line global-require
    // MapboxDirections = require('@simpals/mapbox-gl-directions/dist/mapbox-gl-directions');
  }
  class WithMapRouting extends React.Component {
    constructor(props) {
      super(props);
      this.inited = false;
      // mocking functions for serverside
      this.state = {
        MapGL: props.MapGL,
        mapDirections: {
          routingMachine: {
            getOrigin: () => {},
            getDestination: () => {},
            removeRoutes: () => {},
            actions: {
              setRouteIndex: () => {}
            }
          }
        },
        routeCoords: null,
      };
    }

    static getDerivedStateFromProps = (props, state) => {
      const {
        router: { query },
      } = props;
      const { a, b } = query;
      if (!(a && b)) return { MapGL: props.MapGL };
      return {
        routeCoords: { a: a.split(','), b: b.split(',') },
        MapGL: props.MapGL
      };
    };

    componentDidUpdate() {
      // const { MapGL } = this.state;
      // const { routeCoords } = this.state;
      // if (MapGL.mock || this.inited) return;
      // if (!MapGL.getSource('directions')) {
      //   MapGL.addSource('directions', {
      //     type: 'geojson',
      //     data: {
      //       type: 'FeatureCollection',
      //       features: [],
      //     },
      //   });
      // }
      // this.state.mapDirections = this.initRouting();
      // this.inited = true;
      // if (routeCoords) {
      //   const { a, b } = routeCoords;
      //   this.setNearAddressFromCoords({ lng: a[0], lat: a[1] }, true);
      //   this.setNearAddressFromCoords({ lng: b[0], lat: b[1] });
      //   this.setWaypoint(routeCoords);
      // }
    }

    // componentDidUpdate(prevProps) {
    //   const router = this.props.router;
    //   const prevRouter = prevProps.router;
    //   const { mapDirections } = this.state;
    //   const { routingMachine } = mapDirections;
    //   let routingProfile = router.query.routingMode;
    //   console.log('upadte', routingProfile);
    //   console.log('upadte2', prevRouter.query);
    //   if (routingProfile &&
    //   prevRouter.query.routingMode && routingProfile !== prevRouter.query.routingMode) {
    //     this.handleRoutingMode();
    //     routingMachine.actions.setProfile(routingProfile === 'public' ? 'driving' : routingProfile);
    //   }
    // }

    isIosPlatform = () => {
      if (navigator.userAgent.match(/iPad/i) || navigator.userAgent.match(/iPhone/i)) {
        return true;
      }
      return false;
    }

    getOrigin = () => {
      const { mapDirections } = this.state;
      const { routingMachine } = mapDirections;
      const routeOrigin = routingMachine.getOrigin();
      return routeOrigin.geometry ? routeOrigin.geometry.coordinates : null;
    };

    getDestination = () => {
      const { mapDirections } = this.state;
      const { routingMachine } = mapDirections;
      const routeDestination = routingMachine.getDestination();
      return routeDestination.geometry ? routeDestination.geometry.coordinates : null;
    };

    toggleOSRMRoute = (visible) => {
      const { MapGL } = this.props;
      const visibility = visible ? 'visible' : 'none';
      const publicRoutesVisibility = visible ? 'none' : 'visible';
      MapGL.getStyle().layers.map((layer) => {
        if (layer.id.indexOf('directions-route-line') > -1) {
          MapGL.setLayoutProperty(layer.id, 'visibility', visibility);
        }
        if (layer.id.indexOf('publicRoute') > -1 || layer.id.indexOf('busStops') > -1) {
          MapGL.setLayoutProperty(layer.id, 'visibility', publicRoutesVisibility);
        }
      });
    };

    removeRoutes = () => {
      const { mapDirections } = this.state;
      const { routingMachine } = mapDirections;
      routingMachine.removeRoutes();
    };

    initRouting = () => {
      const { t, router } = this.props;
      const { MapGL } = this.state;
      // if (typeof document === 'undefined' || !MapGL) return;
      let routingProfile = router.query.routingMode;
      if (routingProfile && routingProfile === 'public') {
        routingProfile = 'driving';
      }
      let routeChoosePopup = null;
      const popupHolder = document.createElement('div');
      ReactDOM.render(
        <RoutingDirectionPopup
          t={t}
          setOrigin={this.setOriginFromPopup}
          setDestination={this.setDestinationFromPopup}
        />,
        popupHolder
      );
      routeChoosePopup = new mapboxgl.Popup({
        offset: { bottom: [0, 0] },
        closeOnClick: !(this.isIosPlatform()),
      });
      routeChoosePopup.setDOMContent(popupHolder);
      MapGL.loadImage('/static/images/routing-point.png', (_, image) => {
        if (!MapGL.hasImage('pointRoutings')) {
          MapGL.addImage('pointRoutings', image);
        }
      });
      const routingMachine = null;
      // const routingMachine = new MapboxDirections({
      //   // styles: routingStyles,  not inited to map(need routingMachine.actions.addRoutingStyles() call)
      //   api: hostResolver(OSRM_URL),
      //   alternatives: true,
      //   interactive: false,
      //   insertBefore: null,
      //   unit: 'metric',
      //   profile: routingProfile || 'driving',
      //   onDragUp: this.handleDragUp,
      //   apiAuthHeader: AUTHORIZATION,
      //   controls: {
      //     inputs: false,
      //     instructions: false,
      //     profileSwitcher: false,
      //   },
      // });
      // MapGL.addControl(routingMachine, 'top-right');
      // routingMachine.actions.addRoutingStyles(MapGL);
      this.addRoutingStyles(MapGL);
      // this.addListeners(routingMachine);
      return { routeChoosePopup, routingMachine };
    };

    addRoutingStyles = (map) => {
      const { router } = this.props;
      const { viewType } = router.query;
      const { directions, points } = routingStyles;
      let hasDirections = 0;
      // eslint-disable-next-line max-len
      const beforeLayer = viewType === 'sateliteStyle' ? 'raster-highway-name-path' : 'vector-highway-name-path';
      if (map.getSource('directions')) {
        map.getStyle().layers.map((layer) => {
          if (layer.id.indexOf('directions-') > -1) {
            map.removeLayer(layer.id);
            if (layer.id.match(/-origin|-destination/)) {
              map.addLayer(layer);
            } else {
              map.addLayer(layer, beforeLayer);
            }
            hasDirections += 1;
          }
          return layer;
        });

        if (!hasDirections) {
          directions.forEach((directionsLayer) => {
            map.addLayer(directionsLayer, beforeLayer);
          });
          points.forEach((pointsLayer) => {
            map.addLayer(pointsLayer);
          });
        }
      }
    };

    handleDragUp = (layerId, coords) => {
      const { origin, destination } = coords;
      let currentPoint = origin;
      let isOrigin = true;
      if (layerId === 'directions-destination-point') {
        currentPoint = destination;
        isOrigin = false;
      }
      const lngLat = {
        lng: currentPoint.geometry.coordinates[0],
        lat: currentPoint.geometry.coordinates[1],
      };
      this.setUrlCoords(lngLat, isOrigin);
    };

    addListeners = (routingMachine) => {
      const { MapGL } = this.props;
      if (MapGL.mock) return;
      routingMachine.on('route', this.handleRouteFind);
      // MapGL.on('contextmenu', this.chooseRoutingDirection);
      MapGL.on('mouseenter', 'directions-origin-point', () => {
        routingMachine.interactive(true);
      });
      MapGL.on('mouseleave', 'directions-origin-point', () => {
        routingMachine.interactive(false);
      });
      MapGL.on('mouseenter', 'directions-destination-point', () => {
        routingMachine.interactive(true);
      });
      MapGL.on('mouseleave', 'directions-destination-point', () => {
        routingMachine.interactive(false);
      });

      if (this.isIosPlatform()) {
        // eslint-disable-next-line no-underscore-dangle
        const hammerListner = new Hammer(MapGL._canvasContainer);
        hammerListner.get('press').set({ time: 800 });
        // hammerListner.on('press', (e) => {
        //   const lngLat = MapGL.unproject(e.center);
        //   MapGL.fire('contextmenu', { lngLat });
        // });
        // hammerListner.on('tap', () => {
        //   const { mapDirections } = this.state;
        //   const { routeChoosePopup } = mapDirections;
        //   routeChoosePopup.remove();
        // });
      }
    }

    getNear = async (lngLat, raw) => {
      const { t } = this.props;
      try {
        const { lng, lat } = lngLat;
        const resp = await fetch(`${hostResolver(GET_NEAR_URL)}?lat=${lat}&lon=${lng}`, {
          headers: {
            Authorization: AUTHORIZATION
          }
        });
        const nearMe = await resp.json();
        if (raw) {
          return nearMe;
        }
        if (nearMe.building) {
          return `${nearMe.building.street_name}, ${nearMe.building.number}`;
        }
        if (nearMe.street) {
          return `${nearMe.street.location}, ${nearMe.street.name}`;
        }
        if (nearMe.company && nearMe.company.length) {
          return nearMe.company[0].brand || nearMe.company[0].title;
        }
        if (nearMe.city) {
          return `${nearMe.city.name}`;
        }
        return t('Out of Boundary Paths');
      } catch (err) {
        // eslint-disable-next-line no-console
        console.log(err);
      }
    };

    setNearAddressFromCoords = async ({ lng, lat }, isOrigin) => {
      const { setsOriginAddress, setsDestinationAddress } = this.props;
      let originAddress = '';
      let destinationAddress = '';
      if (isOrigin) {
        originAddress = await this.getNear({ lng, lat });
        setsOriginAddress(originAddress);
        return;
      }
      destinationAddress = await this.getNear({ lng, lat });
      setsDestinationAddress(destinationAddress);
    };

    setNearAddress = async () => {
      const { setsOriginAddress, setsDestinationAddress } = this.props;
      const origin = this.getOrigin();
      const destination = this.getDestination();
      let originAddress = '';
      let destinationAddress = '';
      if (origin) {
        originAddress = await this.getNear({ lng: origin[0], lat: origin[1] });
      }
      if (destination) {
        destinationAddress = await this.getNear({ lng: destination[0], lat: destination[1] });
      }
      setsOriginAddress(originAddress);
      setsDestinationAddress(destinationAddress);
    };

    setRoutingProfile = (profile) => {
      const { mapDirections } = this.state;
      const { routingMachine } = mapDirections;
      routingMachine.actions.setProfile(profile);
    };

    handleRoutingMode = async (mode) => {
      const { router } = this.props;
      const { query } = router;
      const { pageName, routingMode } = query;
      let RMode = routingMode;
      if (!pageName || pageName !== 'routing') {
        await changeURLPage('routing');
      }
      if (mode) {
        addQueryStringToUrl({ routingMode: mode }, true);
        if (mode !== RMode && mode !== 'public') {
          this.setRoutingProfile(mode);
        }
        RMode = mode;
      }
      // eslint-disable-next-line default-case
      switch (RMode) {
        case 'public': {
          this.toggleOSRMRoute(false);
          this.publicRoutesFetch();
          break;
        }
        case 'driving': {
          this.toggleOSRMRoute(true);
          break;
        }
        case 'foot': {
          this.toggleOSRMRoute(true);
          break;
        }
        // default: {
        //   addQueryStringToUrl({ routingMode: 'driving' });
        // }
      }
    };

    handleRouteFind = (route) => {
      const { mapDirections } = this.state;
      const { setsDrivingRoutes, setsWalkingRoutes, router } = this.props;
      const { routeChoosePopup } = mapDirections;
      const {
        query: { routingMode },
      } = router;
      routeChoosePopup.setLngLat([0, 0]);
      this.setUrlCoords();
      // this.handleRoutingMode();
      routingMode === 'foot' ? setsWalkingRoutes(route) : setsDrivingRoutes(route);
    };

    chooseRoutingDirection = (e) => {
      const { lngLat } = e;
      const { MapGL } = this.props;
      const { mapDirections } = this.state;
      const { routeChoosePopup } = mapDirections;
      routeChoosePopup.remove();
      routeChoosePopup.setLngLat([lngLat.lng, lngLat.lat]);
      routeChoosePopup.addTo(MapGL);
    };

    setOriginFromPopup = () => {
      const { mapDirections } = this.state;
      const { routeChoosePopup } = mapDirections;
      const { lng, lat } = routeChoosePopup.getLngLat();
      this.setOrigin({ lng, lat });
      this.setUrlCoords({ lng, lat }, true);
      routeChoosePopup.setLngLat([0, 0]);
    };

    setDestinationFromPopup = () => {
      const { mapDirections } = this.state;
      const { originAddress } = this.props;
      const { routeChoosePopup } = mapDirections;
      const { lng, lat } = routeChoosePopup.getLngLat();
      // TO do refactor
      if ('geolocation' in navigator && !originAddress) {
        routeChoosePopup.setLngLat([0, 0]);
        navigator.geolocation.getCurrentPosition((position) => {
          const { coords: { longitude, latitude } } = position;
          this.setOrigin({ lng: longitude, lat: latitude });
          this.setUrlCoords({ lng: longitude, lat: latitude }, true);
          this.setDestination({ lng, lat });
          this.setUrlCoords({ lng, lat }, false);
          // eslint-disable-next-line no-console
        }, (err) => {
          this.setDestination({ lng, lat });
          this.setUrlCoords({ lng, lat }, false);
          routeChoosePopup.setLngLat([0, 0]);
          // eslint-disable-next-line no-console
          console.log(err);
        });
        return;
      }
      this.setDestination({ lng, lat });
      this.setUrlCoords({ lng, lat }, false);
      routeChoosePopup.setLngLat([0, 0]);
    };

    removeOrigin = () => {
      const { mapDirections } = this.state;
      const { routingMachine } = mapDirections;
      routingMachine.actions.clearOrigin();
      // routingMachine.setOrigin();
    };

    removeDestination = () => {
      const { mapDirections } = this.state;
      const { routingMachine } = mapDirections;
      routingMachine.actions.clearDestination();
    };

    setOrigin = (lngLat) => {
      const { mapDirections } = this.state;
      const { routingMachine } = mapDirections;
      const { lng, lat } = lngLat;
      routingMachine.setOrigin([lng, lat]);
    };

    setDestination = (lngLat) => {
      const { mapDirections } = this.state;
      const { routingMachine } = mapDirections;
      const { lng, lat } = lngLat;
      routingMachine.setDestination([lng, lat]);
    };

    setUrlCoords = (argCoords, isOrigin) => {
      const { lng, lat, lon } = argCoords || {};
      const { router } = this.props;
      const {
        query: { routingMode },
      } = router;
      let origin = this.getOrigin();
      let a = origin ? origin.join(',') : null;
      let destination = this.getDestination();
      let b = destination ? destination.join(',') : null;
      if ((lng || lon) && lat) {
        this.setNearAddressFromCoords({ lng: lng || lon, lat }, isOrigin);
        if (isOrigin) {
          origin = this.getOrigin();
          a = origin ? origin.join(',') : `${lng || lon},${lat}`;
          destination = this.getDestination();
          b = destination ? destination.join(',') : `${lng || lon},${lat}`;
        }
      } else {
        this.setNearAddress();
      }
      const coords = {
        a,
        b,
      };
      addQueryStringToUrl(coords);
      if (!routingMode) {
        this.handleRoutingMode('driving');
      } else if (routingMode === 'public') {
        this.toggleOSRMRoute(false);
        this.publicRoutesFetch();
      }
    };

    setWaypoint = (coords) => {
      const { a, b } = coords;
      const { router } = this.props;
      const { mapDirections } = this.state;
      const { routingMachine } = mapDirections;
      const {
        query: { pageName, routingMode },
      } = router;
      try {
        if (!routingMode || pageName !== 'routing') {
          this.handleRoutingMode('driving');
        }
        if (a && b) {
          if (a.constructor === Array && b.constructor === Array) {
            routingMachine.setOrigin(a);
            routingMachine.setDestination(b);
            return;
          }
          routingMachine.setOrigin([a.lng || a.lon, a.lat]);
          routingMachine.setDestination([b.lng || b.lon, b.lat]);
          return;
        }
        if (a) {
          const { lat, lng, lon } = a;
          this.setUrlCoords({ lng: lng || lon, lat }, true);
          routingMachine.setOrigin([lng || lon, lat]);
        }
        if (b) {
          const { lat, lng, lon } = b;
          this.setUrlCoords({ lng: lng || lon, lat });
          routingMachine.setDestination([lng || lon, lat]);
        }
        if (!(a || b)) {
          this.setUrlCoords();
        }
      } catch (err) {
        // eslint-disable-next-line no-console
        console.log(err);
      }
    };

    revertRoutes = () => {
      const { mapDirections } = this.state;
      const { routingMachine } = mapDirections;
      routingMachine.reverse();
    };

    publicRoutesFetch = async () => {
      const { setsPublicRoutes } = this.props;
      const { mapDirections } = this.state;
      const { routingMachine } = mapDirections;
      if (!(routingMachine.getOrigin().geometry && routingMachine.getDestination().geometry)) {
        // this.initRouting();
        return;
      }
      const src = routingMachine.getOrigin().geometry.coordinates;
      const dst = routingMachine.getDestination().geometry.coordinates;
      const resp = await fetch(
        `${hostResolver(PUBLIC_ROUTES_URL)}?src_y=${src[0]}&src_x=${src[1]}&dst_y=${dst[0]}&dst_x=${dst[1]}`,
        {
          headers: {
            Authorization: AUTHORIZATION
          }
        }
      );
      const json = await resp.json();

      setsPublicRoutes(json);
      return json;
    };

    toggleRoutesActive = (index) => {
      const { mapDirections } = this.state;
      mapDirections.routingMachine.actions.setRouteIndex(index);
    };

    initSelectRoutingDirection = (callBack) => {
      const { MapGL } = this.props;
      MapGL.on('click', 'directions-route-line-alt', (e) => {
        const altLayer = MapGL.queryRenderedFeatures(e.point, { layers: ['directions-route-line-alt'] });
        const index = altLayer[0].properties['route-index'];
        callBack(index);
      });
    };

    showDirectionPoints = (cords) => {
      const { MapGL } = this.props;
      const geojson = {
        type: 'FeatureCollection',
        features: [],
      };
      if (!MapGL.getSource('geojsonDirectionPoints') && !MapGL.getLayer('direction-points')) {
        MapGL.addSource('geojsonDirectionPoints', {
          type: 'geojson',
          data: geojson,
        });
        MapGL.addLayer({
          id: 'direction-points',
          type: 'circle',
          source: 'geojsonDirectionPoints',
          paint: {
            'circle-radius': 5,
            'circle-color': '#FFF',
            'circle-stroke-width': 2,
            'circle-stroke-color': '#F56400',
          },
          filter: ['in', '$type', 'Point'],
        });
      }
      const point = {
        type: 'Feature',
        geometry: {
          type: 'Point',
          coordinates: cords,
        },
        properties: {
          id: String(new Date().getTime()),
          distance: 0,
        },
      };
      geojson.features.push(point);
      MapGL.getSource('geojsonDirectionPoints').setData(geojson);
    };

    hideDirectionPoints = () => {
      const { MapGL } = this.props;
      if (MapGL.getSource('geojsonDirectionPoints') && MapGL.getLayer('direction-points')) {
        MapGL.removeLayer('direction-points');
        MapGL.removeSource('geojsonDirectionPoints');
      }
    };

    flyToDirectionPoint = (coords) => {
      const { MapGL } = this.props;
      MapGL.flyTo({
        center: coords,
        zoom: 16,
      });
    };

    render() {
      let { mapDirections } = this.state;
      if (!mapDirections) mapDirections = {};
      const { routingMachine } = mapDirections;
      return (
        <Component
          {...this.props}
          routingTools={{
            routingMachine,
            getOrigin: this.getOrigin,
            getDestination: this.getDestination,
            setOrigin: this.setOrigin,
            setDestination: this.setDestination,
            removeOrigin: this.removeOrigin,
            removeDestination: this.removeDestination,
            handleRoutingMode: this.handleRoutingMode,
            removeRoutes: this.removeRoutes,
            setWaypoint: this.setWaypoint,
            revertRoutes: this.revertRoutes,
            toggleRoutesActive: this.toggleRoutesActive,
            initSelectRoutingDirection: this.initSelectRoutingDirection,
            showDirectionPoints: this.showDirectionPoints,
            hideDirectionPoints: this.hideDirectionPoints,
            flyToDirectionPoint: this.flyToDirectionPoint,
          }}
        />
      );
    }
  }

  WithMapRouting.propTypes = {
    t: PropTypes.func.isRequired,
    MapGL: PropTypes.object,
    router: PropTypes.object.isRequired,
    setsPublicRoutes: PropTypes.func.isRequired,
    setsDrivingRoutes: PropTypes.func.isRequired,
    setsWalkingRoutes: PropTypes.func.isRequired,
    setsOriginAddress: PropTypes.func.isRequired,
    setsDestinationAddress: PropTypes.func.isRequired,
    originAddress: PropTypes.string,
  };

  WithMapRouting.defaultProps = {
    originAddress: '',
    MapGL: null
  };

  const mapStateToProps = state => ({
    originAddress: state.routing.originAddress,
  });

  const mapDispatchToProps = dispatch => ({
    setsPublicRoutes: routes => dispatch(setPublicRoutes(routes)),
    setsDrivingRoutes: routes => dispatch(setDrivingRoutes(routes)),
    setsWalkingRoutes: routes => dispatch(setWalkingRoutes(routes)),
    setsOriginAddress: address => dispatch(setOriginAddress(address)),
    setsDestinationAddress: address => dispatch(setDestinationAddress(address)),
  });

  return withMap(
    withTranslation()(
      connect(
        mapStateToProps,
        mapDispatchToProps
      )(WithMapRouting)
    )
  );
};
