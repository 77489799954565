import React, { Fragment } from 'react';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { toggleShareModal } from '@/src/redux/actions';
import ShareModal from './ShareModal';

const Button = styled.button`
  padding: 9px 8px;
  display: inline-block;
  color: rgba(0, 0, 0, 0.54);
  &:hover {
    color: ${({ theme }) => theme.colors.accent};
  }
`;

const ToolIcon = styled.span`
  font-size: 1.8rem;
  line-height: 0;
`;

const ShareTool = (props) => {
  const {
    togglerShareModal, isOpened, MapGL, t
  } = props;
  const modalHandler = (isOpen) => {
    togglerShareModal(isOpen);
  };

  return (
    <Fragment>
      <Button data-rh={t('Link')} onClick={() => modalHandler(!isOpened)}>
        <ToolIcon className='icon-share' />
      </Button>
      <ShareModal isOpen={isOpened} modalHandler={modalHandler} MapGL={MapGL} />
    </Fragment>
  );
};

ShareTool.propTypes = {
  MapGL: PropTypes.object.isRequired,
  t: PropTypes.func.isRequired,
  isOpened: PropTypes.bool.isRequired,
  togglerShareModal: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({
  isOpened: state.map.isShareModalOpened,
});

const mapDispatchToProps = dispatch => ({
  togglerShareModal: isOpened => dispatch(toggleShareModal(isOpened)),
});

export default withTranslation()(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(ShareTool)
);
