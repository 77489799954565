import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

const Wrapper = styled.div`
  width: 168px;
`;

const RouteChooseButton = styled.button`
  opacity: 0.87;
  outline: none;
  color: #1a1a1a;
  text-align: left;
  font-size: 1.5rem;
  font-weight: bold;
  line-height: 18px;
  padding: 14px 0 0;
  display: block;
  width: 100%;
  &:first-of-type {
    padding: 0 0 14px;
    border-bottom: 1px solid #ededed;
  }
`;

const RoutingDirectionPopup = (props) => {
  const { t, setOrigin, setDestination } = props;
  return (
    <Wrapper>
      <RouteChooseButton onClick={setOrigin}>{t('Route from')}</RouteChooseButton>
      <RouteChooseButton onClick={setDestination}>{t('Route to')}</RouteChooseButton>
    </Wrapper>
  );
};

RoutingDirectionPopup.propTypes = {
  t: PropTypes.func.isRequired,
  setOrigin: PropTypes.func.isRequired,
  setDestination: PropTypes.func.isRequired,
};
export default RoutingDirectionPopup;
